import React, { useEffect, useRef } from 'react';

export const useMutationObserver = (
    ref: React.MutableRefObject<any>,
    fn: () => void,
) => {
    const mutationObserver = useRef(new MutationObserver(fn));

    useEffect(() => {
        mutationObserver.current.observe(ref.current, {
            subtree: true,
            childList: true,
        });

        return () => {
            mutationObserver.current.disconnect();
        };
    }, []);
};
