import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';
import { NotAccessPage } from 'src/components/Errors/NotAccessPage';
import { NotFound } from 'src/components/Errors/NotFound';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { IndexPage } from 'src/pages/IndexPage/IndexPage';
import { ProcessPage } from 'src/pages/ProcessPage/ProcessPage';
import { RouterStore } from 'src/stores/RouterStore/RouterStore';

export const RoutesLayout = observer(() => {
    return (
        <Switch>
            <Route path={RoutePaths.index} exact component={IndexPage} />
            <Route path={RoutePaths.process} component={ProcessPage} />
            {RouterStore.routes.map((route) => {
                const component = !route.unavailable
                    ? route.Component
                    : NotAccessPage;

                return (
                    <Route
                        key={route.key}
                        path={route.path}
                        exact={route.exact !== false}
                        component={component}
                    />
                );
            })}

            <Route path="*" component={NotFound} />
        </Switch>
    );
});
