import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export type StatusInfo = {
    color: string;
    borderColor: string;
    backgroundColor: string;
    title: React.ReactNode;
};

export const DEFAULT_STATUS_KEY = 'Default';

export type EntityStatusInfoProps = {
    statusInfoMap: Record<string, StatusInfo>;
    status?: string;
    filled?: boolean;
    entityName: string;
};

export const EntityStatusInfo = ({
    statusInfoMap,
    status,
    entityName,
}: EntityStatusInfoProps) => {
    const statusInfo = getStatusInfo(statusInfoMap, status);
    const { t } = useTranslation();

    return (
        <StyledStatus statusInfo={statusInfo}>
            {t(`data:status.${entityName}.${status}`)}
        </StyledStatus>
    );
};

type StyledStatusProps = { statusInfo: StatusInfo };
const StyledStatus = styled.div<StyledStatusProps>`
    color: #000000;
    color: ${(props) => props.statusInfo.color};
    font-size: 1rem;

    &::before {
        content: '';
        display: inline-block;
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 0.6rem;
        margin-right: 0.3rem;
        background: ${(props) => props.statusInfo.color};
    }
`;

export const getStatusInfo = (
    map: Record<string, StatusInfo>,
    status?: string,
): StatusInfo => {
    if (status && map[status]) {
        return map[status];
    }

    return { ...map[DEFAULT_STATUS_KEY], title: status };
};

export const convertMapToList = (map: Record<string, StatusInfo>) => {
    return Object.entries(map)
        .map(([key, value]) => ({
            ...value,
            id: key,
        }))
        .filter((status) => status.id !== DEFAULT_STATUS_KEY);
};
