import { createPopper } from '@popperjs/core';
import React, { useEffect, useRef } from 'react';
import { useMutationObserver } from 'src/hooks/useMutationObserver';

const toggleMenu = (menu: Element) => {
    menu.classList.toggle('open');
};

const outsideDropdownClick = (e: Event) => {
    if (
        !(e.target as HTMLElement)?.matches('.formio-dropdown-trigger button')
    ) {
        document.querySelectorAll('.formio-dropdown-menu').forEach((menu) => {
            menu.classList.remove('open');
        });
    }
};

export function useDropdown(
    containerRef: React.MutableRefObject<HTMLElement | undefined>,
) {
    const popperDestroyFunctions = useRef<Array<() => void>>([]);

    useEffect(() => {
        document.addEventListener('click', outsideDropdownClick);

        return () => {
            document.removeEventListener('click', outsideDropdownClick);
        };
    }, []);

    useMutationObserver(containerRef, () => {
        const dropdowns =
            containerRef.current?.querySelectorAll('.formio-dropdown');
        destroyDropdowns();
        dropdowns && createDropdowns(Array.from(dropdowns as any));
    });

    const destroyDropdowns = () => {
        popperDestroyFunctions.current.forEach((destroy) => {
            destroy();
        });
        popperDestroyFunctions.current = [];
    };

    const createDropdowns = (formioDropdowns: Array<HTMLElement>) => {
        popperDestroyFunctions.current = formioDropdowns
            .map(createDropdown)
            .filter(Boolean) as Array<() => void>;
    };

    const createDropdown = (dropdown: HTMLElement) => {
        const menu = dropdown.querySelector('.formio-dropdown-menu');
        const trigger = dropdown.querySelector(
            '.formio-dropdown-trigger button',
        );

        if (!menu || !trigger) {
            return false;
        }

        const popper = createPopper(dropdown, menu as HTMLElement, {
            placement: 'bottom-end',
        });

        const triggerClickHandler = (e: Event) => {
            e.preventDefault();
            toggleMenu(menu);
            popper.forceUpdate();
        };

        trigger.addEventListener('click', triggerClickHandler);

        return () => {
            popper.destroy();
            trigger.removeEventListener('click', triggerClickHandler);
        };
    };
}
