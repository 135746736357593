import { observer } from 'mobx-react-lite';
import { ReactComponent as LogoImage } from 'src/icons/logo.svg';
import { AgentProfileStore } from 'src/stores/AgentProfileStore/AgentProfileStore';
import styled from 'styled-components';

const StyledWrapper = styled.div`
    color: white;
    display: flex;
    align-items: center;
    width: fit-content;
    height: 100%;

    svg {
        width: 8rem;
    }
`;

export const AgentPortalLogo = observer(() => {
    const logoUrl = AgentProfileStore.currentAgentLogoUrl;
    const agentName = AgentProfileStore.currentAgentName;
    const isProfileLoading = AgentProfileStore.itemLoader.isLoading;
    if (isProfileLoading) return <StyledWrapper />;

    return (
        <StyledWrapper>
            {logoUrl ? (
                <StyledImage src={logoUrl} alt={agentName} />
            ) : (
                <>
                    <LogoImage />
                    <StyledText>| Agent</StyledText>
                </>
            )}
        </StyledWrapper>
    );
});

const StyledText = styled.div`
    margin-left: 5px;
    font-size: 1.3rem;
    font-weight: 600;
    color: #ababab;
`;

const StyledImage = styled.img`
    width: auto;
    max-height: 100%;
    max-width: fit-content;
    margin-right: 1rem;
`;
