import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { EntityStatusInfo } from 'src/components/EntityStatusInfo/EntityStatusInfo';
import {
    MainContainer,
    MainEntityListItemText,
    MasterDetailsAsideItem,
    SecondaryContainer,
    SecondaryEntityListItemText,
} from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { LegalEntity, LoanBrief, Person } from 'src/generated-api-client';
import { LoanStatusInfoMap } from 'src/pages/LoansPage/LoanStatusInfoMap';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { FullNameHelper } from 'src/utils/FullNameHelper';
import { NumberHelper } from 'src/utils/NumberHelper';

type LoanListItemProps = { item: LoanBrief };

export const LoanListItem = observer(({ item }: LoanListItemProps) => {
    const { t, i18n } = useTranslation();
    const currencyMnemonic = SettingsStore.currencyMnemonic as string;

    const fullName = FullNameHelper(
        item?.loanApplication?.borrower as LegalEntity | Person,
    );

    return (
        <MasterDetailsAsideItem to={EntityLinks.loans.itemDetails(item.id)}>
            <MainContainer>
                <Tooltip title={fullName}>
                    <EllipsisContainer>
                        <MainEntityListItemText className="strong-text">{`${item?.loanApplication?.number} `}</MainEntityListItemText>
                        <MainEntityListItemText>
                            {fullName}
                        </MainEntityListItemText>
                    </EllipsisContainer>
                </Tooltip>
                <EntityStatusInfo
                    statusInfoMap={LoanStatusInfoMap}
                    status={item.status}
                    entityName="loan"
                />
            </MainContainer>
            <SecondaryContainer>
                <SecondaryEntityListItemText>
                    <span className="strong-text">
                        {NumberHelper.currencyFormatter(
                            item?.loanApplication?.amountApproved || 0,
                            2,
                            currencyMnemonic,
                            i18n.language,
                        )}
                    </span>
                </SecondaryEntityListItemText>
                <SecondaryEntityListItemText>
                    {item?.loanApplication?.termApproved}{' '}
                    {t(
                        `loan.period.type.${item?.loanApplication?.termPeriodType}`,
                    )}
                </SecondaryEntityListItemText>
                <SecondaryEntityListItemText>
                    <Tooltip title={item?.loanProduct?.code}>
                        <EllipsisContainer>
                            {item?.loanProduct?.code}
                        </EllipsisContainer>
                    </Tooltip>
                </SecondaryEntityListItemText>
            </SecondaryContainer>
        </MasterDetailsAsideItem>
    );
});
