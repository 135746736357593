import { Button, Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LanguageIcon } from 'src/icons/language-icon.svg';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { CssColors } from 'src/styles/CssVariables';
import styled from 'styled-components';

export const LanguageSwitcher = observer(() => {
    const [visible, setVisible] = useState(false);
    const closeMenu = () => {
        setVisible(false);
    };

    if (!SettingsStore.hasFewLocales) {
        return null;
    }

    return (
        <Dropdown
            arrow
            placement="bottomCenter"
            overlay={<Locales closeMenu={closeMenu} />}
            onVisibleChange={(isVisible) => setVisible(isVisible)}
            visible={visible}
            trigger={['click']}
        >
            <StyledButton>
                <StyledLanguageIcon />
            </StyledButton>
        </Dropdown>
    );
});

type LocalesProps = { closeMenu: () => void };

const Locales = observer(({ closeMenu }: LocalesProps) => {
    const { t, i18n } = useTranslation();

    return (
        <StyledMenu
            onClick={(e) => {
                e.domEvent.preventDefault();
                closeMenu();
            }}
        >
            {SettingsStore.supportedLocales.map((locale) => {
                return (
                    <MenuItem
                        key={locale}
                        onClick={() => SettingsStore.setLocale(locale)}
                        className={`${
                            i18n.language === locale ? 'active' : ''
                        }`}
                    >
                        {t(`data:locale.${locale}`)}
                    </MenuItem>
                );
            })}
        </StyledMenu>
    );
});

const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    font-size: 0.98rem;
    color: #d2d2d7;
    border: none;
    box-shadow: none;
    background: transparent;
    margin-right: 0.3rem;
    text-transform: capitalize;
    padding: 0.5rem 0.7rem;

    &:focus,
    &:hover {
        color: #d2d2d7;
        background-color: #222325;
    }
`;

const StyledMenu = styled(Menu)`
    border: none;
    width: 11rem;
    border-radius: 0.4rem;
    box-shadow: 0px 9px 24px rgba(22, 26, 29, 0.23) !important;
    padding: 1.2rem 1rem;
    flex: 1;
    .ant-menu-item {
        color: #8b959e;
        .ant-menu-title-content {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:after {
            display: none;
        }

        + .ant-menu-item {
            padding: 0.24rem 1.1rem;
        }

        &:hover {
            color: black;
        }

        &.ant-menu-item-selected,
        &:active {
            background-color: transparent;
        }
    }
    .active {
        color: #000000;
    }
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1.1rem;
    color: #6e6e73;
    line-height: 125%;
    letter-spacing: -0.3px;
    padding: 0.6rem 1.1rem 0.6rem 0.6rem;
    cursor: pointer;
    &:hover {
        color: ${CssColors.black};
        background: #f5f5f7;
    }
`;

const StyledLanguageIcon = styled(LanguageIcon)`
    width: 1.1rem;
    height: 1.1rem;
`;
