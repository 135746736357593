import _ from 'lodash';
import { computed } from 'mobx';
import { AgentBrief } from 'src/generated-api-client';
import { agentsApi, bpmsResourcesManagerApi } from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/AgentsStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type AgentsFilter = {
    search?: string;
    taxId?: string;
};

export class AgentsStoreClass extends BasicStore<AgentBrief> {
    itemDetailsFormKey = 'embedded:deployment:forms/bo/bo-26-agent.json';

    itemEditFormKey = 'embedded:deployment:forms/bo/bo-28-edit-agent.json';

    itemCreateFormKey = 'embedded:deployment:forms/bo/bo-27-create-agent.json';

    pager = new Pager();

    api: BasicStoreApi<AgentBrief> = {
        loadList: async () => {
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                agentsApi.findAllAgents(
                    this.pager.page,
                    this.pager.size,
                    InitialFilters.defaultSort,
                    this.filterCriteria.filter.search,
                    this.filterCriteria.filter.taxId,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },
        loadItemForm: (formKey: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(formKey),
            );
        },
        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(agentsApi.getAgent(id));
        },
        updateItem: (id, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                agentsApi.updateAgent(Number(id), data),
            );
        },
        createItem: (data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                agentsApi.createAgent(data),
            );
        },
    };

    filterCriteria: FilterCriteria<AgentsFilter> = new FilterCriteria();

    @computed get hasFilter() {
        const { taxId, search } = this.filterCriteria.filter;

        return !_.isNil(taxId) || !_.isNil(search);
    }
}

export const AgentsStore = new AgentsStoreClass();
