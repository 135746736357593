import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { LoanApplication } from 'src/generated-api-client';
import { LoanApplicationListItem } from 'src/pages/LoanApplicationsPage/LoanApplicationListItem';
import { LoanApplicationsHeaderPanel } from 'src/pages/LoanApplicationsPage/LoanApplicationsHeaderPanel';
import { LoanApplicationsStore } from 'src/stores/LoanApplicationsStore/LoanApplicationsStore';

const store = LoanApplicationsStore;

export const LoanApplicationsPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="loan-applications"
            store={store}
            asidePanel={<LoanApplicationsAsidePanel />}
        >
            <EntityPresentation store={store} entityName="loan-application" />
        </MasterDetailsPage>
    );
});

const LoanApplicationsAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: LoanApplication) => {
                return (
                    <List.Item key={item.id}>
                        <LoanApplicationListItem item={item} />
                    </List.Item>
                );
            }}
            header={<LoanApplicationsHeaderPanel />}
        />
    );
});
