/* eslint-disable no-underscore-dangle */
/* eslint-disable max-params */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable complexity */
import { Formio } from 'formiojs';
import Components from 'formiojs/components/Components';
import _ from 'lodash';

const SelectfieldComponent = (Components as any).components.select;

class SelectComponent extends (SelectfieldComponent as any) {
    getOptionTemplate(data: any, value: any) {
        if (!this.component.template) {
            return data.label;
        }
        const options = {
            noeval: true,
            data: {},
        };
        const template = this.sanitize(
            this.component.template
                ? this.interpolate(this.component.template, { item: data })
                : data.label,
            this.shouldSanitizeValue,
        );
        const templateValue =
            this.component.reference && value?._id
                ? value._id.toString()
                : value;
        if (
            templateValue &&
            !_.isObject(templateValue) &&
            (options.data as any).item
        ) {
            // If the value is not an object, then we need to save the template data off for when it is selected.
            this.templateData[templateValue] = (options.data as any).item;
        }

        return template;
    }

    encodeDataToUriStrings(obj: any): any {
        if (typeof obj !== 'object' || obj === null) {
            // Base case: If the object is not an object or is null, return it as is
            return obj;
        }

        if (Array.isArray(obj)) {
            // If it's an array, map over its elements and recursively encode them
            return obj.map((item) => this.encodeDataToUriStrings(item));
        }

        // If it's an object, use Object.keys and reduce to recursively encode string values
        return Object.keys(obj).reduce((encodedObj, key) => {
            const value = obj[key];
            if (typeof value === 'string') {
                encodedObj[key] = encodeURIComponent(value);
            } else {
                encodedObj[key] = this.encodeDataToUriStrings(value);
            }

            return encodedObj;
        }, {} as any);
    }

    loadItems(
        url: string,
        search: string,
        headers: any,
        options: any,
        method: string,
        body: any,
    ) {
        options = options || {};

        // See if we should load items or not.
        if (!this.shouldLoad || (!this.itemsFromUrl && this.options.readOnly)) {
            this.isScrollLoading = false;
            this.loading = false;
            this.itemsLoadedResolve();

            return;
        }

        // See if they have not met the minimum search requirements.
        const minSearch = parseInt(this.component.minSearch, 10);
        if (
            this.component.searchField &&
            minSearch > 0 &&
            (!search || search.length < minSearch)
        ) {
            // Set empty items.
            return this.setItems([]);
        }

        // Ensure we have a method and remove any body if method is get
        method = method || 'GET';
        if (method.toUpperCase() === 'GET') {
            body = null;
        }

        const limit = this.component.limit || 100;
        const skip = this.isScrollLoading ? this.selectOptions.length : 0;
        const query: any = this.component.disableLimit
            ? {}
            : {
                  limit,
                  skip,
              };

        // Allow for url interpolation.
        url = this.interpolate(url, {
            formioBase: Formio.getBaseUrl(),
            search,
            limit,
            skip,
            page: Math.abs(Math.floor(skip / limit)),
        });

        // Add search capability.
        if (this.component.searchField && search) {
            const searchValue = Array.isArray(search)
                ? search.join(',')
                : search;

            query[this.component.searchField] =
                this.component.searchField.endsWith('__regex')
                    ? _.escapeRegExp(searchValue)
                    : searchValue;
        }

        // If they wish to return only some fields.
        if (this.component.selectFields) {
            query.select = this.component.selectFields;
        }

        // Add sort capability
        if (this.component.sort) {
            query.sort = this.component.sort;
        }

        if (!_.isEmpty(query)) {
            // Add the query string.
            url +=
                (!url.includes('?') ? '?' : '&') +
                Formio.serialize(query, (item: string) =>
                    this.interpolate(item),
                );
        }

        // Add filter capability
        if (this.component.filter) {
            const formattedData = this.encodeDataToUriStrings(this.root.data);

            url +=
                (!url.includes('?') ? '?' : '&') +
                this.interpolate(this.component.filter, {
                    data: formattedData,
                });
        }

        // Set ignoreCache if it is
        options.ignoreCache = this.component.ignoreCache;

        // Make the request.
        options.header = headers;
        this.loading = true;

        Formio.makeRequest(
            this.options.formio,
            'select',
            url,
            method,
            body,
            options,
        )
            .then((response: any) => {
                this.loading = false;
                this.error = null;
                this.setItems(response, !!search);
            })
            .catch((err: Error) => {
                if (this.itemsFromUrl) {
                    this.setItems([]);
                    this.disableInfiniteScroll();
                }

                this.isScrollLoading = false;
                this.handleLoadingError(err);
            });
    }
}

export default SelectComponent;
