import { Layout, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { LanguageSwitcher } from 'src/components/LanguageSwitcher/LanguageSwitcher';
import { Logo } from 'src/components/Logo/Logo';
import { UserMenu } from 'src/components/UserMenu';
import { RouterStore } from 'src/stores/RouterStore/RouterStore';
import { CssVariables } from 'src/styles/CssVariables';
import styled from 'styled-components';

export const Header = observer(() => {
    useTranslation();

    return (
        <StyledHeader>
            <Logo />
            <StyledMenu mode="horizontal">
                {RouterStore.menuItems.map((router) => {
                    return (
                        <Menu.Item key={router.key}>
                            <NavLink
                                to={router.menuLink || router.path}
                                activeClassName="active"
                            >
                                {router.menuTitle?.()}
                            </NavLink>
                        </Menu.Item>
                    );
                })}
            </StyledMenu>
            <LanguageSwitcher />
            <UserMenu />
        </StyledHeader>
    );
});

const StyledHeader = styled(Layout.Header)`
    display: flex;
    align-items: center;
    padding: 0 1.5rem 0 2.5rem;
    height: ${CssVariables.headerHeight};
    background-color: var(--header-background);
    line-height: ${CssVariables.headerHeight};
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
`;

const StyledMenu = styled(Menu)`
    background-color: var(--header-background);
    flex: 1;
    color: var(--header-text-color);
    border-bottom: none;

    .ant-menu-title-content {
        a {
            color: var(--header-text-color);

            &:hover {
                color: var(--header-active-link-color);
                text-decoration: none;
            }
        }
    }

    &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
        padding: 0;
    }

    .ant-menu-item {
        &:after {
            display: none;
        }

        + .ant-menu-item {
            margin-left: 0.1rem;
        }

        a {
            font-size: var(--header-font-size);
            line-height: 165%;
            display: inline-block;
            padding: 0.17rem 1rem;
            border-radius: 0.34rem;

            letter-spacing: -0.02em;

            color: var(--header-text-color);
            background-color: transparent;

            &:hover {
                background-color: var(--header-active-btn-bg);
            }
        }

        a.active {
            background-color: var(--header-active-btn-bg);
            color: var(--header-text-color);
        }
    }
`;
