import { action, computed, observable } from 'mobx';
import { TaskRepresentation } from 'src/generated-api-client';
import { taskManagerApi } from 'src/services/apiServices';
import { TaskActionTypes } from 'src/stores/TaskWithActionStore/TaskActionTypes.types';
import { AsyncOperationWithStatus } from 'src/utils/BasicStore/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

const DEFAULT_TASKS_LIST_SIZE = 20;
const DEFAULT_TASKS_PAGE_NUMBER = 0;
export class AssignedTasksStoreClass extends BasicStore<TaskRepresentation> {
    api: BasicStoreApi<TaskRepresentation> = {
        loadList: async () => {
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                taskManagerApi.listAssigned(
                    DEFAULT_TASKS_PAGE_NUMBER,
                    DEFAULT_TASKS_LIST_SIZE,
                ),
            );

            return resp.content || [];
        },
    };

    @computed get list() {
        return this.filterUserTasks(
            this.listLoader.data || [],
        ) as any as TaskRepresentation[];
    }

    @observable getAssignedList() {
        return this.listLoader.data || [];
    }

    @action filterUserTasks(tasks: TaskRepresentation[] | undefined) {
        return tasks?.filter(
            (task) =>
                ![
                    TaskActionTypes.AUTOCOMPLETE,
                    TaskActionTypes.FILE_DOWNLOAD,
                ]?.includes(
                    (task?.extensions as unknown as { action: TaskActionTypes })
                        ?.action,
                ),
        );
    }

    itemDetailsFormKey = '';

    unclaimTaskAction = new AsyncOperationWithStatus((taskId: string) =>
        RequestHelper.unwrapFromAxiosPromise(taskManagerApi.unclaim(taskId)),
    );

    @action async unclaim(id: string) {
        await this.unclaimTaskAction.call(id);
        await this.loadList();
    }
}

export const AssignedTasksStore = new AssignedTasksStoreClass();
