import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { LoanApplication } from 'src/generated-api-client';
import { AgentLoanApplicationListItem } from 'src/pages/AgentLoanApplicationsPage/AgentLoanApplicationListItem';
import { AgentLoanApplicationsHeaderPanel } from 'src/pages/AgentLoanApplicationsPage/AgentLoanApplicationsHeaderPanel';
import { AgentLoanApplicationsStore } from 'src/stores/AgentLoanApplicationsStore/AgentLoanApplicationsStore';

const store = AgentLoanApplicationsStore;

export const AgentLoanApplicationsPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="agent-loan-applications"
            store={store}
            asidePanel={<AgentLoanApplicationsAsidePanel />}
        >
            <EntityPresentation
                store={store}
                entityName="agent-loan-application"
            />
        </MasterDetailsPage>
    );
});

const AgentLoanApplicationsAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: LoanApplication) => {
                return (
                    <List.Item key={item.id}>
                        <AgentLoanApplicationListItem item={item} />
                    </List.Item>
                );
            }}
            header={<AgentLoanApplicationsHeaderPanel />}
        />
    );
});
