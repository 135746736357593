// eslint-disable-next-line simple-import-sort/sort
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { FormioEvent } from 'src/core/Formio.types';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import styled from 'styled-components';
import { TaskViewViewModel } from 'src/pages/TasksPage/TaskViewViewModel';
import { BasePage, PageType } from 'src/components/BasePage';
import { CurrentTaskStore } from 'src/stores/CurrentTaskStore/CurrentTaskStore';
import { TaskStore } from 'src/stores/TaskStore/TaskStore';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

export const UrgentTaskView = observer(() => {
    const taskId = TaskStore.getUrgentTaskId;

    useEffect(() => {
        TaskViewViewModel.loadItem(taskId);

        return () => {
            TaskViewViewModel.reset();
            TaskStore.resetUrgentTask();
        };
    }, [taskId]);

    const submitHandler = useMemo(() => {
        return getSubmitHandler(taskId);
    }, [taskId]);

    const { isLoading } = TaskViewViewModel.loadingStatus;

    return (
        <StyledPage
            pageName="urgentTask"
            isLoading={isLoading}
            type={PageType.FULL}
        >
            {taskId ? (
                <>
                    <TaskViewHeader />
                    <FormioForm
                        form={TaskViewViewModel.taskForm as any}
                        submission={TaskViewViewModel.taskVariables}
                        onSubmit={submitHandler as any}
                        normalizeSubmission
                    />
                </>
            ) : null}
        </StyledPage>
    );
});

const TaskViewHeader = observer(() => {
    const { t } = useTranslation();

    if (!TaskViewViewModel.taskDefinition) {
        return null;
    }
    const { taskDefinition } = TaskViewViewModel;

    const hasL10nTitleKey = Boolean(taskDefinition.extensions?.l10nTitleKey);
    const title = hasL10nTitleKey
        ? t(`${taskDefinition.extensions?.l10nTitleKey}`, {
              ...taskDefinition?.taskInfo,
          })
        : taskDefinition.name;

    return (
        <StyledHeaderRow>
            <StyledTitlte>{title}</StyledTitlte>
        </StyledHeaderRow>
    );
});

const getSubmitHandler = (taskId: string) => {
    let isSubmitting = false;

    return async (values: FormioEvent) => {
        if (isSubmitting) {
            return;
        }
        isSubmitting = true;

        const { data } = values;
        await submitForm(taskId, data);
    };
};

const submitForm = async (taskId: string, data: any) => {
    await TaskStore.submitForm(taskId, data);
    TaskStore.resetUrgentTask();
    CurrentTaskStore.reset();
    TaskStore.loadAssignedTaskList();
};

const StyledPage = styled(BasePage)`
    width: 100%;
    height: 100vh;
    padding: 2rem 1.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    background: #ffffff;
`;

const StyledHeaderRow = styled.div`
    margin-bottom: 1.8rem;
    display: flex;
    justify-content: center;
`;

const StyledTitlte = styled(Typography.Title)`
    width: 70%;
    padding-left: 1.6rem;
    text-align: center;
`;
