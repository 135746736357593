export const form = `
<div id="{{ctx.id}}" class="{{ctx.classes}} {% if (ctx.disabled) { %}input-group-disabled{% } %}"{% if (ctx.styles) { %} styles="{{ctx.styles}}"{% } %} ref="component">
{% if (ctx.visible && ctx.component.type !== "refreshComponent") { %}
  {{ctx.children}}
  {% if (!ctx.disabled) { %}
    <div ref="messageContainer" class="formio-errors invalid-feedback"></div>
  {% } %}
{% } %}
</div>
`;
