import {
    convertMapToList,
    DEFAULT_STATUS_KEY,
    StatusInfo,
} from 'src/components/EntityStatusInfo/EntityStatusInfo';
import { CssColors } from 'src/styles/CssVariables';

export const TransactionStatusInfoMap: Record<string, StatusInfo> = {
    SUCCESSFUL: {
        title: 'Successful',
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        backgroundColor: CssColors.mainGreen,
    },
    REJECTED: {
        title: 'Rejected',
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        backgroundColor: CssColors.mainRed,
    },
    PENDING: {
        title: 'Pending',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    CANCELED: {
        title: 'Canceled',
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        backgroundColor: CssColors.mainRed,
    },
    IN_PROGRESS: {
        title: 'In progress',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    FAILED: {
        title: 'Failed',
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        backgroundColor: CssColors.mainRed,
    },
    [DEFAULT_STATUS_KEY]: {
        title: '',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
};

export const getTransactionStatuses = () => {
    return convertMapToList(TransactionStatusInfoMap);
};
