/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import { FormioFormProps } from 'src/components/FormioForm/FormioFormProps';
import { FormioCustomEvent } from 'src/core/Formio.types';

export const getCustomEventHandler = (type: string) => {
    return CUSTOM_EVENT_HANDLERS[type];
};

export type CustomEventHandler = (
    event: FormioCustomEvent<any>,
    formioFormProps: FormioFormProps<any>,
) => Promise<void>;

const CUSTOM_EVENT_HANDLERS: Record<string, CustomEventHandler> = {
    submitWithoutData: async (event, { onSubmit }) => {
        if (onSubmit) {
            const data = _.pick(event.data, [event.component.key]);
            await onSubmit(data);
        }
    },
};
