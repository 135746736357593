import { AgentPortalLogo } from 'src/components/Logo/AgentPortalLogo';
import { BOLogo } from 'src/components/Logo/BOLogo';
import { Env } from 'src/core/Environment';

export const Logo = () => {
    if (Env.isAgentPortal) {
        return <AgentPortalLogo />;
    }

    return <BOLogo />;
};
