import { action, observable } from 'mobx';

export enum PageSizes {
    size10 = 10,
    size20 = 20,
    size50 = 50,
    size100 = 100,
}
export class Pager {
    @observable page = 0;
    @observable total = 0;
    @observable size: PageSizes = PageSizes.size20;

    constructor(private defaultPageSize: PageSizes = PageSizes.size20) {}

    @action setPage(page: number) {
        this.page = page;
    }

    @action setSize(size: PageSizes) {
        this.size = size;
    }

    @action setTotal(total: number) {
        this.total = total;
    }

    @action reset() {
        this.setPage(0);
        this.setSize(this.defaultPageSize);
    }
}
