import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { Loan } from 'src/generated-api-client';
import { LoanListItem } from 'src/pages/LoansPage/LoanListItem';
import { LoansHeaderPanel } from 'src/pages/LoansPage/LoansFilterPanel';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { OperationsStore } from 'src/stores/OperationsStore/OperationsStore';

export const OperationsPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="operations"
            store={LoansStore}
            asidePanel={<OperationsAsidePanel />}
        >
            <EntityPresentation
                store={OperationsStore}
                entityName="operations"
            />
        </MasterDetailsPage>
    );
});

const OperationsAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={LoansStore}
            renderItem={(item: Loan) => {
                return (
                    <List.Item key={item.id}>
                        <LoanListItem item={item} />
                    </List.Item>
                );
            }}
            header={<LoansHeaderPanel />}
        />
    );
});
