const hasValue = (value: any) => {
    if (typeof value === 'string') {
        return value !== '';
    }

    if (Array.isArray(value)) {
        return value.length > 0;
    }

    return value !== null && value !== undefined;
};

export const DataHelper = {
    hasValue,
};
