import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { LeadBrief } from 'src/generated-api-client';
import { LeadsListItem } from 'src/pages/LeadsPage/LeadsListItem';
import { LeadsPageHeaderPanel } from 'src/pages/LeadsPage/LeadsPageHeaderPanel';
import { LeadsStore } from 'src/stores/LeadsStore/LeadsStore';

const store = LeadsStore;

export const LeadsPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="leads"
            store={store}
            asidePanel={<LeadsAsidePanel />}
        >
            <EntityPresentation store={store} entityName="leads" />
        </MasterDetailsPage>
    );
});

const LeadsAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: LeadBrief) => {
                return (
                    <List.Item key={item.id}>
                        <LeadsListItem item={item} />
                    </List.Item>
                );
            }}
            header={<LeadsPageHeaderPanel />}
        />
    );
});
