import _ from 'lodash';
import { computed } from 'mobx';
import { Campaign } from 'src/generated-api-client';
import {
    bpmsResourcesManagerApi,
    campaignsApi,
} from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/CampaignsStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type CampaignsFilter = {
    search?: string;
    maxAmount?: number;
    maxTerm?: number;
    active?: boolean;
};

export class CampaignsStoreClass extends BasicStore<Campaign> {
    itemDetailsFormKey = 'embedded:deployment:forms/bo/bo-19-campaign.json';

    itemEditFormKey = 'embedded:deployment:forms/bo/bo-21-edit-campaign.json';

    itemCreateFormKey =
        'embedded:deployment:forms/bo/bo-20-create-campaign.json';

    pager = new Pager();

    api: BasicStoreApi<Campaign> = {
        loadList: async () => {
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                campaignsApi.findAllCampaigns(
                    this.pager?.page,
                    this.pager.size,
                    InitialFilters.defaultSort,
                    this.filterCriteria.filter.maxAmount,
                    this.filterCriteria.filter.maxTerm,
                    this.filterCriteria.filter.active,
                    this.filterCriteria.filter.search,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },

        loadItemForm: (formKey: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(formKey),
            );
        },
        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                campaignsApi.getCampaign(id),
            );
        },
        updateItem: (id, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                campaignsApi.updateCampaign(Number(id), data),
            );
        },
        createItem: (data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                campaignsApi.createCampaign(data),
            );
        },
    };

    filterCriteria: FilterCriteria<CampaignsFilter> = new FilterCriteria(
        InitialFilters.defaultFilters,
    );

    @computed get hasFilter() {
        const { maxAmount, maxTerm, search } = this.filterCriteria.filter;

        return !_.isNil(maxAmount) || !_.isNil(maxTerm) || !_.isNil(search);
    }
}

export const CampaignsStore = new CampaignsStoreClass();
