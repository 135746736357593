import { observer } from 'mobx-react-lite';
import { Spinner } from 'src/components/Spinner/Spinner';
import styled from 'styled-components';

export const SplashScreen = observer(() => {
    return (
        <StyledAppSplashScreen>
            <Spinner />
        </StyledAppSplashScreen>
    );
});

const StyledAppSplashScreen = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
