/* eslint-disable sonarjs/cognitive-complexity */
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import { Spinner } from 'src/components/Spinner/Spinner';
import { Router } from 'src/core/router/Router';
import {
    ComponentEventInstance,
    ProcessContextDefenition,
} from 'src/stores/ProcessStore/ProcessContextDefenition.util';
import { ProcessStoreProvider } from 'src/stores/ProcessStore/ProcessStore';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import styled from 'styled-components';

export type EntityDetailsProps = {
    store: BasicStore<any>;
};
export const EntityDetails = observer(({ store }: EntityDetailsProps) => {
    const { params, url } = useRouteMatch<{ entityId: string }>();
    const { entityId } = params;

    useEffect(() => {
        if (!entityId) {
            return;
        }
        store.loadItem(entityId);
        store.loadForm(store.itemDetailsFormKey);

        return () => {
            store.formLoader.reset();
            store.itemLoader.reset();
        };
    }, [entityId]);

    const loadingStatus = useMemo(() => {
        return new CombinedLoadingStatus([store.formLoader, store.itemLoader]);
    }, []);

    if (loadingStatus.isLoading) {
        return <StyledSpinner />;
    }

    return (
        <div>
            <FormioForm
                key={`${entityId}-details`}
                form={store.currentItemForm as any}
                submission={{ data: store.currentItem } as any}
                onCustomEvent={(event) => {
                    if (
                        event.type === 'startProcess' &&
                        (event.component as any).properties?.processKey
                    ) {
                        const processKey = (event.component as any).properties
                            ?.processKey;

                        const contextKey = (event.component as any).properties
                            ?.context;

                        const process =
                            ProcessStoreProvider.getInstance(processKey);

                        const useContextWrapperValue = (event.component as any)
                            .properties?.useContextWrapper;

                        if (
                            _.has(
                                event.component.properties,
                                'useContextWrapper',
                            )
                        ) {
                            const value = useContextWrapperValue !== 'false';
                            process.setUseContextWrapper(value);
                        }

                        if (contextKey) {
                            const context = ProcessContextDefenition(
                                contextKey,
                                event as ComponentEventInstance,
                            );
                            process.setContext(context);
                        }

                        Router.navigate(`${url}/process/${processKey}`);
                    }

                    if (event.type === 'edit') {
                        Router.navigate(`${url}/edit`);
                    }

                    if (event.type === 'create') {
                        const entity = (event.component as any).properties
                            ?.entity;
                        const queryParams = (event.component as any).properties
                            ?.urlParams;
                        Router.navigate(`/${entity}/create?${queryParams}`);
                    }

                    return Promise.resolve();
                }}
            />
        </div>
    );
});

const StyledSpinner = styled(Spinner)`
    margin: 0 auto;
    display: block;
`;
