import _ from 'lodash';
import {
    Action,
    AppFeature,
    EntityConfig,
    ReadablePermissions,
    UserRole,
} from 'src/stores/UserStore/UserStore.types';

export const userPermissionsConfig: Record<AppFeature, EntityConfig> = {
    tasks: {
        view: [
            UserRole.Verifier,
            UserRole.Underwriter,
            UserRole.CreditOfficer,
            UserRole.CollectionManager,
        ],
        manage: [
            UserRole.Verifier,
            UserRole.Underwriter,
            UserRole.CreditOfficer,
            UserRole.CollectionManager,
        ],
    },
    applications: {
        view: [
            UserRole.Verifier,
            UserRole.Underwriter,
            UserRole.CreditOfficer,
            UserRole.CollectionManager,
        ],
    },

    loans: {
        view: [
            UserRole.Underwriter,
            UserRole.CreditOfficer,
            UserRole.CollectionManager,
        ],
        manage: [UserRole.CreditOfficer],
    },
    borrowers: {
        view: [
            UserRole.Verifier,
            UserRole.Underwriter,
            UserRole.CreditOfficer,
            UserRole.CollectionManager,
        ],
    },

    products: {
        view: [
            UserRole.CreditOfficer,
            UserRole.CollectionManager,
            UserRole.Underwriter,
        ],
        manage: [UserRole.CreditOfficer],
    },

    campaigns: {
        view: [
            UserRole.Underwriter,
            UserRole.CreditOfficer,
            UserRole.CollectionManager,
        ],
        manage: [UserRole.CreditOfficer],
    },

    transactions: {
        view: [UserRole.CreditOfficer, UserRole.CollectionManager],
    },

    operations: {
        view: [
            UserRole.CreditOfficer,
            UserRole.CollectionManager,
            UserRole.Agent,
            UserRole.PartnerManager,
        ],
    },
    collections: {
        view: [
            UserRole.Underwriter,
            UserRole.CreditOfficer,
            UserRole.CollectionManager,
        ],
    },
    agents: {
        view: [UserRole.PartnerManager],
        manage: [UserRole.PartnerManager],
    },
    leads: {
        view: [UserRole.Agent, UserRole.PartnerManager],
        manage: [UserRole.Agent, UserRole.PartnerManager],
    },
    profile: {
        view: [UserRole.Agent],
        manage: [UserRole.Agent],
    },
    agentLoanApplications: {
        view: [UserRole.Agent],
        manage: [UserRole.Agent],
    },
    agentLoans: {
        view: [UserRole.Agent],
        manage: [UserRole.Agent],
    },
    operationsAgent: {
        view: [UserRole.Agent],
        manage: [UserRole.Agent],
    },
};

export const generateReadablePermissions = (
    userRoles: UserRole[],
    positive: boolean,
) => {
    const allActions: Array<Action> = ['manage', 'view'];

    return Object.fromEntries(
        allActions.map((action) => {
            const calculatedActionPermissions = Object.fromEntries(
                Object.entries(userPermissionsConfig).map(
                    ([entity, entityConfig = {}]: [string, EntityConfig]) => {
                        const actionRoles = entityConfig[action] || [];
                        const canDoActionsWithEntity =
                            _.intersection(actionRoles, userRoles).length > 0;

                        return [
                            entity,
                            positive
                                ? canDoActionsWithEntity
                                : !canDoActionsWithEntity,
                        ];
                    },
                ),
            );

            return [action, calculatedActionPermissions];
        }),
    ) as ReadablePermissions;
};
