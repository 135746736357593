/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Col, Input, Row, Tabs } from 'antd';
import { customComponents } from 'hes-formio-components';
import { useState } from 'react';
import { FormBuilder } from 'react-formio';
import { BasePage } from 'src/components/BasePage';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import styled from 'styled-components';

const { TabPane } = Tabs;
const { TextArea } = Input;

const customComponentsKeys = Object.keys(customComponents || {});

const components = customComponentsKeys?.reduce((init, module) => {
    return { ...init, [module]: true };
}, {});

const transformSchemaToValidateString = (schema: Record<string, any>) => {
    try {
        return JSON.stringify(schema, undefined, 4);
    } catch (error) {
        console.error(error);

        return '';
    }
};

const transformStringToValidateSchema = (schema: string) => {
    try {
        return JSON.parse(schema);
    } catch (error) {
        console.error(error);

        return '';
    }
};

export const FormioSandbox = () => {
    const [stringFormSchema, setStringFormSchema] = useState('');
    const [JSONFormData, setJSONFormData] = useState({
        data: {},
    });
    const [stringFormData, setStringFormData] = useState('');
    const [formSchema, setFormSchema] = useState({
        display: 'form',
    });

    const handleSchemaChange = (schema: any) => {
        const formattedSchema = transformSchemaToValidateString(schema);
        setStringFormSchema(formattedSchema);
    };

    const handleSchemaAreaChange = (value: string) => {
        setStringFormSchema(value);
        const JSONSchema = transformStringToValidateSchema(value);
        setFormSchema(JSONSchema);
    };

    const handleSubmissionAreaChange = (value: string) => {
        setStringFormData(value);
        const JSONSchema = transformStringToValidateSchema(value);
        setJSONFormData(JSONSchema);
    };

    const handleFormChange = (value: any) => {
        const dataValue = {
            data: value,
        };
        const formattedSchema = transformSchemaToValidateString(dataValue);
        setStringFormData(formattedSchema);
    };

    return (
        <BasePage pageName="formioSandbox">
            <StyledBuilderContainer>
                <Tabs>
                    <TabPane key="builder" tab="Builder">
                        <FormBuilder
                            form={formSchema}
                            options={{
                                builder: {
                                    customComponents: {
                                        title: 'HES Components',
                                        components: {
                                            Well: true,
                                            ...components,
                                        },
                                    },
                                },
                            }}
                            onSaveComponent={(
                                currentComponent: any,
                                componentInstance: any,
                                scheme: any,
                            ) => {
                                handleSchemaChange(scheme);
                                setFormSchema(scheme);
                            }}
                        />
                    </TabPane>
                    <TabPane key="schema" tab="JSON Schema">
                        <StyledTextArea
                            showCount
                            value={stringFormSchema}
                            placeholder="Please enter Form Schema."
                            onChange={(evn) =>
                                handleSchemaAreaChange(evn.target.value)
                            }
                        />
                    </TabPane>
                    <TabPane key="form" tab="Form with submission">
                        <Row gutter={24} justify="space-between">
                            <Col span={11}>
                                <FormioForm
                                    onChange={({ data }) =>
                                        handleFormChange(data)
                                    }
                                    form={formSchema}
                                    submission={JSONFormData}
                                />
                            </Col>
                            <Col span={11}>
                                <StyledTextArea
                                    showCount
                                    value={stringFormData}
                                    placeholder="Please enter Form Submission."
                                    onChange={(evn) =>
                                        handleSubmissionAreaChange(
                                            evn.target.value,
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </StyledBuilderContainer>
        </BasePage>
    );
};

const StyledBuilderContainer = styled.div`
    padding-top: 3.53rem;
    .builder-sidebar_search {
        display: none;
    }
    .formcomponents {
        width: 13.2rem;
        flex: 0 0 25%;
        max-width: 28%;
    }
    .builder-group-button {
        box-shadow: 0 0 0 0.2rem rgb(10 101 237 / 25%);
        margin-bottom: 0.6rem;
    }
    .formarea {
        max-width: 70%;
    }
`;

const StyledTextArea = styled(TextArea)`
    textarea {
        width: 100%;
        font-size: 12;
        background: #f5f5f5;
        font-family: ui-monospace, SFMono-Regular, SF Mono, Consolas,
            Liberation Mono, Menlo, monospace;
        height: 29rem;
    }
`;
