import _ from 'lodash';
import { computed } from 'mobx';
import { LeadBrief } from 'src/generated-api-client';
import { bpmsResourcesManagerApi, leadsApi } from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/LeadsStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type LeadsFilter = {
    search?: string;
};

export class LeadsStoreClass extends BasicStore<LeadBrief> {
    itemDetailsFormKey = 'embedded:deployment:forms/ap/ap-06-lead.json';

    itemEditFormKey = 'embedded:deployment:forms/ap/ap-07-edit-lead.json';

    itemCreateFormKey = 'embedded:deployment:forms/ap/ap-05-create-lead.json';

    pager = new Pager();

    api: BasicStoreApi<LeadBrief> = {
        loadList: async () => {
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                leadsApi.findAllLeads(
                    this.pager?.page,
                    this.pager.size,
                    InitialFilters.defaultSort,
                    this.filterCriteria.filter.search,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },
        loadItemForm: (formKey: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(formKey),
            );
        },
        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(leadsApi.getLead(id));
        },
        updateItem: (id, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                leadsApi.updateLead(Number(id), data),
            );
        },
        createItem: (data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                leadsApi.createLead(data),
            );
        },
    };

    filterCriteria: FilterCriteria<LeadsFilter> = new FilterCriteria();

    @computed get hasFilter() {
        const { search } = this.filterCriteria.filter;

        return !_.isNil(search);
    }
}

export const LeadsStore = new LeadsStoreClass();
