import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { TransactionBrief } from 'src/generated-api-client';
import { TransactionListItem } from 'src/pages/TransactionsPage/TransactionListItem';
import { TransactionsHeaderPanel } from 'src/pages/TransactionsPage/TransactionsHeaderPanel';
import { TransactionsStore } from 'src/stores/TransactionsStore/TransactionsStore';

const store = TransactionsStore;

export const TransactionsPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="transactions"
            store={store}
            asidePanel={<TransactionsAsidePanel />}
        >
            <EntityPresentation store={store} entityName="transaction" />
        </MasterDetailsPage>
    );
});

const TransactionsAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: TransactionBrief) => {
                return (
                    <List.Item key={item.id}>
                        <TransactionListItem item={item} />
                    </List.Item>
                );
            }}
            header={<TransactionsHeaderPanel />}
        />
    );
});
