import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { EntityStatusInfo } from 'src/components/EntityStatusInfo/EntityStatusInfo';
import {
    MainContainer,
    MainEntityListItemText,
    MasterDetailsAsideItem,
    SecondaryContainer,
    SecondaryEntityListItemText,
} from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Campaign } from 'src/generated-api-client';
import { ProductsStatusInfoMap } from 'src/pages/ProductsPage/ProductsStatusInfoMap';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { NumberHelper } from 'src/utils/NumberHelper';

type CampaignsListItemProps = { item: Campaign };

export const CampaignsListItem = observer(
    ({ item }: CampaignsListItemProps) => {
        const currencyMnemonic = SettingsStore.currencyMnemonic as string;
        const { i18n } = useTranslation();

        return (
            <MasterDetailsAsideItem
                to={EntityLinks.campaigns.itemDetails(item.id)}
            >
                <MainContainer>
                    <Tooltip title={item.code}>
                        <EllipsisContainer>
                            <MainEntityListItemText className="strong-text">{`${item.id} `}</MainEntityListItemText>
                            <MainEntityListItemText>
                                {item.name}
                            </MainEntityListItemText>
                        </EllipsisContainer>
                    </Tooltip>
                    <EntityStatusInfo
                        statusInfoMap={ProductsStatusInfoMap}
                        status={getStatus(item.active)}
                        entityName="product"
                    />
                </MainContainer>
                <SecondaryContainer>
                    <SecondaryEntityListItemText>
                        <span className="strong-text">
                            {NumberHelper.currencyFormatter(
                                item?.parameters!.minAmount || 0,
                                2,
                                currencyMnemonic,
                                i18n.language,
                            )}
                            {' - '}
                            {NumberHelper.currencyFormatter(
                                item?.parameters!.maxAmount || 0,
                                2,
                                currencyMnemonic,
                                i18n.language,
                            )}
                        </span>
                    </SecondaryEntityListItemText>
                    <SecondaryEntityListItemText>
                        {NumberHelper.formatToPercent(
                            item?.parameters!.interestRatePct || 0,
                            2,
                        )}
                    </SecondaryEntityListItemText>
                </SecondaryContainer>
            </MasterDetailsAsideItem>
        );
    },
);

const getStatus = (status?: boolean | null) => {
    if (status) {
        return 'ACTIVE';
    }

    return 'INACTIVE';
};
