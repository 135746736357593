/* eslint-disable sonarjs/cognitive-complexity */
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import { Spinner } from 'src/components/Spinner/Spinner';
import { Router } from 'src/core/router/Router';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import styled from 'styled-components';

export type EntityProfileDetailsProps = {
    store: BasicStore<any>;
};
export const EntityProfileDetails = observer(
    ({ store }: EntityProfileDetailsProps) => {
        const { params, url } = useRouteMatch<{ entityId: string }>();
        const { entityId } = params;

        useEffect(() => {
            if (!entityId) {
                return;
            }
            store.loadItem(entityId);
            store.loadForm(store.itemDetailsFormKey);

            return () => {
                store.formLoader.reset();
            };
        }, [entityId]);

        const loadingStatus = useMemo(() => {
            return new CombinedLoadingStatus([
                store.formLoader,
                store.itemLoader,
            ]);
        }, []);

        if (loadingStatus.isLoading) {
            return <StyledSpinner />;
        }

        return (
            <div>
                <FormioForm
                    key={`${entityId}-details`}
                    form={store.currentItemForm as any}
                    submission={{ data: store.currentItem } as any}
                    onCustomEvent={(event) => {
                        if (event.type === 'edit') {
                            Router.navigate(`${url}/edit`);
                        }

                        return Promise.resolve();
                    }}
                />
            </div>
        );
    },
);

const StyledSpinner = styled(Spinner)`
    margin: 0 auto;
    display: block;
`;
