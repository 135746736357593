import _ from 'lodash';
import { action } from 'mobx';
import { TaskWithActionViewModel } from 'src/components/FormForTaskWithAction/TaskWithActionViewModel';
import { taskActionHandlers } from 'src/stores/TaskWithActionStore/taskActionHandlers';
import { TaskActionTypes } from 'src/stores/TaskWithActionStore/TaskActionTypes.types';

export class TaskActionManagerClass {
    @action taskActionExecutor(
        taskViewModel: typeof TaskWithActionViewModel,
        formInstance: any,
    ) {
        const taskAction = (
            taskViewModel.currentTaskDefinition?.extensions as unknown as {
                action: TaskActionTypes;
            }
        )?.action as TaskActionTypes;

        const actionHandler = this.getActionHandler(taskAction);

        actionHandler?.(taskViewModel, formInstance);
    }

    private getActionHandler(action: string) {
        if (!action) return;

        return _.get(taskActionHandlers, action);
    }
}

export const TaskActionManager = new TaskActionManagerClass();
