import { DatePicker as AntDatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const DateRangePicker = (props: RangePickerProps) => {
    const { t } = useTranslation();

    return (
        <StyledDateRangeWrapper>
            <AntDatePicker.RangePicker
                placeholder={[
                    t('Component.DateRangePicker.Start'),
                    t('Component.DateRangePicker.End'),
                ]}
                {...props}
            />
        </StyledDateRangeWrapper>
    );
};

const StyledDateRangeWrapper = styled.div`
    .ant-picker {
        border: 1px solid var(--input-border-color);
    }

    .ant-picker-input > input {
        font-size: 1rem;
        color: var(--text-color);

        &::placeholder {
            color: var(--text-color);
        }
    }

    .ant-picker-separator {
        display: flex;
        align-items: center;
        color: var(--text-color);
    }

    .ant-picker-suffix {
        display: none;
    }

    .ant-picker-focused {
        box-shadow: none;
        border-color: var(--input-border-color-focus);
    }

    .ant-picker-active-bar {
        display: none;
    }
`;
