import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { generateReadablePermissions } from 'src/stores/UserStore/UserPermissions.config';
import { UserRole } from 'src/stores/UserStore/UserStore.types';

export type UserProfile = {
    username: string;
};

export class UserStoreClass {
    @observable private _userProfile?: Keycloak.KeycloakProfile;

    @computed get userProfile() {
        // eslint-disable-next-line no-underscore-dangle
        return this._userProfile;
    }

    @action async loadUserInfo() {
        try {
            // eslint-disable-next-line no-underscore-dangle
            this._userProfile = await SettingsStore.keycloak.loadUserProfile();
        } catch (error) {
            console.error(error);
        }
    }

    @computed get roles() {
        const realmAccessRoles =
            SettingsStore.keycloak.tokenParsed?.realm_access?.roles || [];

        const resourceAccessRoles =
            (
                SettingsStore.keycloak.tokenParsed?.resource_access?.[
                    SettingsStore.settings?.appName || ''
                ] as any
            )?.roles || [];

        return [...resourceAccessRoles, ...realmAccessRoles] as UserRole[];
    }

    hasRole(role: string) {
        return (
            this.roles
                .map((x) => x.toLowerCase())
                .includes(role.toLowerCase()) || false
        );
    }

    hasAnyRole(...roles: string[]) {
        return (
            _.intersection(
                this.roles.map((role) => role.toLowerCase()),
                roles.map((role) => role.toLowerCase()),
            ).length > 0
        );
    }

    @computed get can() {
        return generateReadablePermissions(this.roles, true);
    }

    @computed get canNot() {
        return generateReadablePermissions(this.roles, false);
    }
}
export const UserStore = new UserStoreClass();
