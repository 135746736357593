import { NumberHelper } from 'src/utils/NumberHelper';

export const InterestRateHelper = (entity: {
    minInterestRatePct?: number;
    maxInterestRatePct?: number;
}) => {
    return `${NumberHelper.formatToPercent(
        entity?.minInterestRatePct || 0,
        2,
    )} - ${NumberHelper.formatToPercent(entity?.maxInterestRatePct || 0, 2)}`;
};
