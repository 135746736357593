import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { LegalEntity, Person } from 'src/generated-api-client';
import { BorrowerListItem } from 'src/pages/BorrowersPage/BorrowerListItem';
import { BorrowersHeaderPanel } from 'src/pages/BorrowersPage/BorrowersHeaderPanel';
import { BorrowersStore } from 'src/stores/BorrowersStore/BorrowersStore';

const store = BorrowersStore;

export const BorrowersPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="borrowers"
            store={store}
            asidePanel={<BorrowersAsidePanel />}
        >
            <EntityPresentation store={store} entityName="borrower" />
        </MasterDetailsPage>
    );
});

const BorrowersAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: LegalEntity | Person) => {
                return (
                    <List.Item key={item.taxNumber}>
                        <BorrowerListItem item={item} />
                    </List.Item>
                );
            }}
            header={<BorrowersHeaderPanel />}
        />
    );
});
