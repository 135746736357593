const getTargetUrl = (urlTemplate: string, params: Record<string, any>) => {
    return Object.entries(params).reduce((template, [key, value]) => {
        return template.replace(`:${key}`, value || '');
    }, urlTemplate);
};

const urlSearchToObject = (params: string) => {
    return Object.fromEntries(new URLSearchParams(params));
};

const getFiltersFromUrl = (params: string) => {
    return Object.fromEntries(
        Object.entries(Object.fromEntries(new URLSearchParams(params)))
            .filter((item) => item[0].includes('filter'))
            .map((filterItem) => [filterItem[0].split('.')[1], filterItem[1]]),
    );
};

const downloadFile = (url: string, file: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = file;
    a.target = '_blank';
    a.click();
};

export const UrlHelper = {
    getTargetUrl,
    urlSearchToObject,
    getFiltersFromUrl,
    downloadFile,
};
