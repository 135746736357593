import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { EntityStatusInfo } from 'src/components/EntityStatusInfo/EntityStatusInfo';
import {
    MainContainer,
    MainEntityListItemText,
    MasterDetailsAsideItem,
    SecondaryContainer,
    SecondaryEntityListItemText,
} from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { LegalEntity, LoanApplication, Person } from 'src/generated-api-client';
import { ApplicationStatusInfoMap } from 'src/pages/LoanApplicationsPage/ApplicationStatusInfoMap';
import { DateHelper } from 'src/utils/DateHelper';
import { FullNameHelper } from 'src/utils/FullNameHelper';

type AgentLoanApplicationListItemProps = { item: LoanApplication };

export const AgentLoanApplicationListItem = observer(
    ({ item }: AgentLoanApplicationListItemProps) => {
        const fullName = FullNameHelper(item.borrower as LegalEntity | Person);

        return (
            <MasterDetailsAsideItem
                to={EntityLinks.agentLoanApplications.itemDetails(item.id)}
            >
                <MainContainer>
                    <Tooltip title={fullName}>
                        <EllipsisContainer>
                            <MainEntityListItemText className="strong-text">{`${item.number} `}</MainEntityListItemText>
                            <MainEntityListItemText>
                                {fullName}
                            </MainEntityListItemText>
                        </EllipsisContainer>
                    </Tooltip>
                    <EntityStatusInfo
                        statusInfoMap={ApplicationStatusInfoMap}
                        status={item.status}
                        entityName="application"
                    />
                </MainContainer>
                <SecondaryContainer>
                    <SecondaryEntityListItemText>
                        <span className="strong-text">
                            {item?.campaign?.name}
                        </span>
                    </SecondaryEntityListItemText>
                    <SecondaryEntityListItemText>
                        {DateHelper.format(item.requestedAt || '')}
                    </SecondaryEntityListItemText>
                </SecondaryContainer>
            </MasterDetailsAsideItem>
        );
    },
);
