import {
    convertMapToList,
    DEFAULT_STATUS_KEY,
    StatusInfo,
} from 'src/components/EntityStatusInfo/EntityStatusInfo';
import { CssColors } from 'src/styles/CssVariables';

export const CollectionsStatusInfoMap: Record<string, StatusInfo> = {
    IN_PROGRESS: {
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        title: 'In progress',
        backgroundColor: CssColors.mainGreen,
    },
    SUCCESSFUL: {
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        title: 'Successful',
        backgroundColor: CssColors.mainGreen,
    },
    FAILED: {
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        title: 'Failed',
        backgroundColor: CssColors.mainRed,
    },
    [DEFAULT_STATUS_KEY]: {
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        title: '',
        backgroundColor: CssColors.mainGray,
    },
};

export const getCollectionStatuses = () => {
    return convertMapToList(CollectionsStatusInfoMap);
};
