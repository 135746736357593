import { Spin } from 'antd';
import 'antd/dist/antd.css';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'src/App.css';
import { Spinner } from 'src/components/Spinner/Spinner';
import { AppLayout } from 'src/containers/AppLayout/AppLayout';
import { BrowserRouterBehavior } from 'src/core/router/BrowserRouterBehavior';
import 'src/formioSettings';
import 'src/services/api.config';
import 'src/services/apiInterceptors';
import { AppStore } from 'src/stores/AppStore/AppStore';

Spin.setDefaultIndicator(<Spinner />);

const App = observer(() => {
    useEffect(() => {
        AppStore.init();
    }, []);

    return (
        <BrowserRouter>
            <BrowserRouterBehavior />
            <AppLayout />
        </BrowserRouter>
    );
});

export default App;
