import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const NotAccessPage = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledError>
            <h1>{t('Page.NotAccess.Title', { defaultValue: 'Not access' })}</h1>
            <p>
                {t('Page.NotAccess.Description', {
                    defaultValue: 'You have not access to requested page',
                })}
            </p>
        </StyledError>
    );
});

const StyledError = styled.div`
    z-index: 115;
    width: 100%;
    height: 100%;
    text-align: center;

    h1 {
        transform: translateY(650%);
    }

    p {
        transform: translateY(1300%);
    }
`;
