/* eslint-disable import/order */
/* eslint-disable simple-import-sort/sort */
import React from 'react';
import ReactDOM from 'react-dom';

import App from 'src/App';
import reportWebVitals from 'src/reportWebVitals';

import 'formiojs';
import 'formiojs/dist/formio.full.css';

import 'src/styles/formio-modal.css';
import 'src/styles/index.scss';

import 'jquery';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.querySelector('#root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// FIX: CRA can't read declaration from global.d.ts and index.d.ts file.
declare module 'url' {
    interface Url {
        search: string | null;
    }
    interface UrlWithParsedQuery {
        search: string | null | undefined;
    }
}
