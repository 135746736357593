export enum AppName {
    BO_PORTAL = 'bo-portal',
    AGENT_PORTAL = 'agent-portal',
}

export type EnvType = {
    appName: AppName | string;
    isDev: boolean;
    isAgentPortal: boolean;
    isBOportal: boolean;
};

const appName = process.env.REACT_APP_APP_NAME || AppName.BO_PORTAL;
export const Env: EnvType = {
    appName,
    isDev: process.env.NODE_ENV === 'development',
    isAgentPortal: appName === AppName.AGENT_PORTAL,
    isBOportal: appName === AppName.BO_PORTAL,
};
