import { action, observable } from 'mobx';

export class FilterCriteria<Filter extends Record<string, any>> {
    @observable sort?: string[];
    @observable filter: Filter;

    constructor(private initialFilter: Filter = {} as Filter) {
        this.filter = this.initialFilter;
    }

    @action setFilter(filter: Filter) {
        this.filter = filter;
    }

    @action applyFilter(filter: Filter) {
        this.filter = { ...this.filter, ...filter };
    }

    @action resetFilter() {
        this.filter = {} as Filter;
    }
}
