import { AppName, Env } from 'src/core/Environment';
import { EntityNames } from 'src/core/router/EntityLinks';

type FeatureList = EntityNames;

export const featureList: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [key in FeatureList]: {
        unavailable: boolean;
    };
} = {
    tasks: {
        unavailable: false,
    },
    applications: {
        unavailable: false,
    },
    agentLoanApplications: {
        unavailable: Env.appName === AppName.BO_PORTAL,
    },
    loans: {
        unavailable: false,
    },
    products: {
        unavailable: false,
    },

    campaigns: {
        unavailable: false,
    },

    borrowers: {
        unavailable: false,
    },

    transactions: {
        unavailable: false,
    },

    operations: {
        unavailable: false,
    },
    collections: {
        unavailable: false,
    },
    agents: {
        unavailable: false,
    },
    leads: {
        unavailable: Env.appName === AppName.BO_PORTAL,
    },
    agentLoans: {
        unavailable: Env.appName === AppName.BO_PORTAL,
    },
    agentOperations: {
        unavailable: Env.appName === AppName.BO_PORTAL,
    },
};
