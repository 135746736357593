/* eslint-disable sonarjs/no-small-switch */

export enum ContextNames {
    row = 'row',
}

type eventProcesses = 'startProcess';

export interface ComponentEventInstance {
    component: any;
    data: any;
    event: any;
    type: eventProcesses;
}

const getDataGridRowData = (componentEventInstance: ComponentEventInstance) => {
    return componentEventInstance.data;
};

export const ProcessContextDefenition = (
    contextName: ContextNames,
    componentEventInstance: ComponentEventInstance,
) => {
    switch (contextName) {
        case ContextNames.row:
            return getDataGridRowData(componentEventInstance);
        default:
            return componentEventInstance.data;
    }
};
