import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import { EntityStatusInfo } from 'src/components/EntityStatusInfo/EntityStatusInfo';
import {
    MainContainer,
    MainEntityListItemText,
    MasterDetailsAsideItem,
    SecondaryContainer,
    SecondaryEntityListItemText,
} from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { CollectionBrief } from 'src/generated-api-client';
import { CollectionsStatusInfoMap } from 'src/pages/CollectionsPage/CollectionsStatusInfoMap';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { DateHelper } from 'src/utils/DateHelper';
import { FullNameHelper } from 'src/utils/FullNameHelper';
import { NumberHelper } from 'src/utils/NumberHelper';

type CollectionListItemProps = { item: CollectionBrief };

export const CollectionListItem = observer(
    ({ item }: CollectionListItemProps) => {
        const currencyMnemonic = SettingsStore.currencyMnemonic as string;
        const { i18n } = useTranslation();

        const fullName = FullNameHelper(
            item.loan?.loanApplication?.borrower as any,
        );

        return (
            <MasterDetailsAsideItem
                to={EntityLinks.collections.itemDetails(item.id)}
            >
                <MainContainer>
                    <Tooltip title={fullName}>
                        <EllipsisContainer>
                            <MainEntityListItemText className="strong-text">{`${item.loan?.loanApplication.number} `}</MainEntityListItemText>
                            <MainEntityListItemText>
                                {fullName}
                            </MainEntityListItemText>
                        </EllipsisContainer>
                    </Tooltip>
                    <EntityStatusInfo
                        statusInfoMap={CollectionsStatusInfoMap}
                        status={item.status}
                        entityName="collection"
                    />
                </MainContainer>
                <SecondaryContainer>
                    <SecondaryEntityListItemText>
                        <span className="strong-text">
                            {NumberHelper.currencyFormatter(
                                item.loan?.actualSnapshot?.outstandingBalance ||
                                    0,
                                2,
                                currencyMnemonic,
                                i18n.language,
                            )}
                        </span>
                    </SecondaryEntityListItemText>
                    <SecondaryEntityListItemText>
                        {DateHelper.formatDate(item.startedAt)}
                    </SecondaryEntityListItemText>
                </SecondaryContainer>
            </MasterDetailsAsideItem>
        );
    },
);
