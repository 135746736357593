import { Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { ReactComponent as MenuDots } from 'src/icons/user-menu-dots.svg';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { UserStore } from 'src/stores/UserStore/UserStore';
import styled from 'styled-components';

const StyledMenuItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 125%;
    letter-spacing: -0.3px;
    padding: 0.6rem 1.1rem 0.6rem 0.6rem;
    &:hover {
        color: var(--user-menu-color-hover);
        background: var(--user-menu-bg);
    }
`;

const StyledMenuItemOuterLink = styled.a`
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 125%;
    letter-spacing: -0.3px;
    padding: 0.6rem 1.1rem 0.6rem 0.6rem;
    text-decoration: none;
    &:hover {
        color: var(--user-menu-color-hover);
        background: var(--user-menu-bg);
        text-decoration: none;
    }
`;

const StyledMenuItemInnerLink = styled(NavLink)`
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 125%;
    letter-spacing: -0.3px;
    padding: 0.6rem 1.1rem 0.6rem 0.6rem;
    text-decoration: none;
    &:hover {
        color: var(--user-menu-color-hover);
        background: var(--user-menu-bg);
        text-decoration: none;
    }
`;

const MenuItem = styled.div`
    font-weight: 400;
    font-size: 0.9rem;
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 125%;
    letter-spacing: -0.3px;
    cursor: pointer;
    text-transform: capitalize;
    &:hover {
        color: var(--user-menu-color-hover);
        background: var(--user-menu-bg);
    }
`;

const StyledMenu = styled(Menu)`
    width: 14rem;
    border-radius: 0.4rem;
    box-shadow: 0px 9px 24px rgba(22, 26, 29, 0.23) !important;
    padding: 1.2rem 1rem;
`;

type authRealmType = 'BorrowerRealm' | 'Backoffice';
interface PopupMenuProps {
    utils: {
        authServerUrl: string;
        authRealm: authRealmType;
    };
}

const PopupMenu = ({ utils }: PopupMenuProps) => {
    const { t } = useTranslation();

    return (
        <StyledMenu>
            {UserStore.can.view.profile && (
                <MenuItem>
                    <StyledMenuItemInnerLink to="/profile/me">
                        {t('Menu.User.Account')}
                    </StyledMenuItemInnerLink>
                </MenuItem>
            )}
            <MenuItem>
                <StyledMenuItemOuterLink
                    href={RoutePaths.profile(
                        utils.authServerUrl,
                        utils.authRealm,
                    )}
                    target="_blank"
                >
                    {t('Menu.User.AccountSettings')}
                </StyledMenuItemOuterLink>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    AuthStore.logout();
                }}
            >
                <StyledMenuItem>{t('Logout')}</StyledMenuItem>
            </MenuItem>
        </StyledMenu>
    );
};

export const UserMenu = observer(() => {
    const settingsStore = SettingsStore;

    return (
        <Dropdown
            overlay={() => (
                <PopupMenu
                    utils={{
                        authServerUrl: settingsStore?.settings
                            ?.authServerUrl as string,
                        authRealm: settingsStore?.settings
                            ?.authRealm as authRealmType,
                    }}
                />
            )}
            placement="bottomRight"
            trigger={['click']}
            arrow
        >
            <StyledDotsWrapper>
                <StyledMenuDots />
            </StyledDotsWrapper>
        </Dropdown>
    );
});

const StyledDotsWrapper = styled.button`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-left: 1rem;
    cursor: pointer;
    padding: 1rem;
    border-radius: 0.5rem;
    border: none;
    background: transparent;

    &:focus,
    &:hover {
        color: #d2d2d7;
        background-color: #222325;
    }
`;

const StyledMenuDots = styled(MenuDots)`
    width: 2rem;
`;
