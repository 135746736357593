import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { Empty } from 'src/components/Empty/Empty';
import { CssVariables } from 'src/styles/CssVariables';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

export type AsidePanelProps<T = any> = {
    store: BasicStore<T>;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    renderItem: (item: T) => React.ReactNode;

    filterPanel?: React.ReactNode;
    header?: React.ReactNode;
};

export const MasterDetailsAside = observer(
    ({ store, renderItem, header }: AsidePanelProps<any>) => {
        return (
            <StyledList
                dataSource={store.list}
                renderItem={renderItem}
                locale={{
                    emptyText: <Empty />,
                }}
                pagination={getPagination(store)}
                header={header}
                loading={store.listLoader.isLoading}
            />
        );
    },
);

const getPagination = (store: BasicStore<any>) => {
    if (store.pager) {
        return {
            onChange: (page: number, size = 20) => {
                store.pager?.setPage(page - 1);
                store.pager?.setSize(size);
                store.loadList();
            },
            pageSize: store.pager.size,
            current: store.pager.page + 1,
            total: store.pager.total,
            showSizeChanger: false,
            responsive: true,
            showLessItems: true,
        };
    }

    return false;
};

export const StyledList = styled(List)`
    height: 100%;
    width: ${CssVariables.masterDetailsAsideWidth};
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-right: 1rem;

    .ant-spin-nested-loading {
        flex: 1;
        padding: 0 0 0 1.2rem;
    }

    .ant-list-pagination {
        padding: 0.82rem 1.41rem 0.82rem 0.3rem;
        margin-top: 0;
    }

    .ant-list-item {
        padding: 0;
        display: block;
    }

    .ant-list-header {
        padding: 0.8rem 0 0.1rem 1.2rem;
        border: none;
    }
`;
