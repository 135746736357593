import { action, computed, observable } from 'mobx';
import { TaskRepresentation } from 'src/generated-api-client';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { AvailableTasksStore } from 'src/stores/AvailabaleTasksStore/AvailableTasksStore';
import { CurrentTaskStore } from 'src/stores/CurrentTaskStore/CurrentTaskStore';
import { TasksStore } from 'src/stores/TasksStore/TasksStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';

export class TaskViewViewModelClass {
    @action async loadItem(id: string) {
        await Promise.all([
            CurrentTaskStore.loadItem(id),
            CurrentTaskStore.loadForm(id),
            TasksStore.loadItem(id),
        ]);

        this.setCurrentTask(id);
    }

    @action setCurrentTask(id: string) {
        this.taskDefinition = TasksStore.getTaskById(id);
    }

    @observable taskDefinition?: TaskRepresentation;

    @computed get taskVariables() {
        return CurrentTaskStore.currentItem
            ? { data: CurrentTaskStore.currentItem }
            : undefined;
    }

    @computed get taskForm() {
        return CurrentTaskStore.currentItemForm;
    }

    @computed get loadingStatus() {
        return new CombinedLoadingStatus([
            CurrentTaskStore.itemLoader,
            CurrentTaskStore.formLoader,
            CurrentTaskStore.claimTaskAction,
            CurrentTaskStore.unclaimTaskAction,
            TasksStore.listLoader,
        ]);
    }

    @action async claim(id?: string) {
        if (!id) return;
        await AvailableTasksStore.claim(id);
        await AssignedTasksStore.loadList();
        if (id === TaskViewViewModel.taskDefinition?.id) {
            await this.loadItem(id);
        }
    }

    @action async unclaim(id?: string) {
        if (!id) return;
        await AssignedTasksStore.unclaim(id);
        await AvailableTasksStore.loadList();
        if (id === TaskViewViewModel.taskDefinition?.id) {
            await this.loadItem(id);
        }
    }

    @action async submitForm(id: string, values: any) {
        const data = await CurrentTaskStore.submitForm(id, values);
        await AssignedTasksStore.loadList();

        return data;
    }

    @action reset() {
        CurrentTaskStore.reset();
    }
}

export const TaskViewViewModel = new TaskViewViewModelClass();
