import { getFileUrl } from 'src/services/apiServices';

type BackendFile = {
    name: string;
    type: string;
    url?: string;
    storage: string;
};

type FormioFile = Required<BackendFile>;
const STORAGE_TYPE = 'url';

export const convertToFormioFileModels = (
    files: Array<BackendFile> | any,
): Array<FormioFile> | any => {
    if (Array.isArray(files) && files.every(isFileModel)) {
        return files.map((file) => {
            if (file.url && file.storage) {
                return file as FormioFile;
            }

            return {
                name: file.name,
                type: file.type,
                url: getFileUrl(file.name),
                storage: STORAGE_TYPE,
            };
        });
    }

    return files;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum FileModelsPropNames {
    name = 'name',
    type = 'type',
}
const isFileModel = (data: any): data is BackendFile => {
    const keys = Object.keys(data);
    if (
        keys.includes(FileModelsPropNames.name) &&
        keys.includes(FileModelsPropNames.type)
    ) {
        return true;
    }

    return false;
};
