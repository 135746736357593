import _ from 'lodash';
import { computed } from 'mobx';
import { LoanProduct } from 'src/generated-api-client';
import { bpmsResourcesManagerApi, productsApi } from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/ProductsStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type ProductsFilter = {
    search?: string;
    active?: boolean;
    maxAmount?: number;
    maxTerm?: number;
    productGroupId?: string;
    campaignId?: string;
};

export class ProductsStoreClass extends BasicStore<LoanProduct> {
    itemDetailsFormKey = 'embedded:deployment:forms/bo/bo-14-product.json';

    itemEditFormKey = 'embedded:deployment:forms/bo/bo-16-edit-product.json';

    itemCreateFormKey =
        'embedded:deployment:forms/bo/bo-15-create-product.json';

    pager = new Pager();

    api: BasicStoreApi<LoanProduct> = {
        loadList: async () => {
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                productsApi.findAllProducts(
                    this.pager?.page,
                    this.pager.size,
                    InitialFilters.defaultSort,
                    this.filterCriteria.filter.maxAmount,
                    this.filterCriteria.filter.maxTerm,
                    this.filterCriteria.filter.campaignId
                        ? Number(this.filterCriteria.filter.campaignId)
                        : undefined,
                    undefined,
                    this.filterCriteria.filter.active,
                    this.filterCriteria.filter.search,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },

        loadItemForm: (formKey: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(formKey),
            );
        },
        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.getProduct(id),
            );
        },
        updateItem: (id, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.updateProduct(Number(id), data),
            );
        },
        createItem: (data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.createProduct(data),
            );
        },
    };

    filterCriteria: FilterCriteria<ProductsFilter> = new FilterCriteria();

    @computed get hasFilter() {
        const { active, maxAmount, maxTerm, search, campaignId } =
            this.filterCriteria.filter;

        return (
            !_.isNil(active) ||
            !_.isNil(maxAmount) ||
            !_.isNil(maxTerm) ||
            !_.isNil(search) ||
            !_.isNil(campaignId)
        );
    }
}

export const ProductsStore = new ProductsStoreClass();
