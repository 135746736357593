/* eslint-disable max-lines */
import { Form, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SearchPanel } from 'src/components/SearchPanel/SearchPanel';
import {
    SearchPanelFilterForm,
    SearchPanelFilterFormProps,
} from 'src/components/SearchPanel/SearchPanelFilterForm';
import {
    BorrowersFilter,
    BorrowersStore,
} from 'src/stores/BorrowersStore/BorrowersStore';
import styled from 'styled-components';

const store = BorrowersStore;

export const BorrowersHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <SearchPanel
            store={store}
            renderPanel={(props) => <FilterPanel {...props} />}
            placeholder={t('Page.Borrowers.AsideHeader.Search.Placeholder')}
        />
    );
});

const FilterPanel = observer((props: SearchPanelFilterFormProps) => {
    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{ blackList: '' }}
            onFinish={({ ...values }: BorrowersFilter) => {
                store.pager.reset();
                store.filterCriteria?.applyFilter({
                    ...values,
                });
                store.loadList();
            }}
        >
            <StyledSelect
                label={t('Page.Borrowers.Filter.Status')}
                name="blackList"
            >
                <Select allowClear>
                    <Select.Option value="true">
                        {t(`data:status.borrower.BLACKLISTED`)}
                    </Select.Option>
                    <Select.Option value="false">
                        {t(`data:status.borrower.NOT_BLACKLISTED`)}
                    </Select.Option>
                </Select>
            </StyledSelect>
        </SearchPanelFilterForm>
    );
});

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        height: 2.7rem;
        border-radius: 0.5rem;
    }
`;
