/* eslint-disable max-classes-per-file */
import { LoanApplicationBorrowerBrief } from 'src/generated-api-client';
import {
    agentLoanApplicationsApi,
    bpmsResourcesManagerApi,
} from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/AgentLoanApplicationsStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type LoanApplicationFilters = {
    search?: string;
    issueDateFrom?: moment.Moment;
    issueDateTo?: moment.Moment;
    status?: string[];
};

export class AgentLoanApplicationsStoreClass extends BasicStore<
    LoanApplicationBorrowerBrief,
    LoanApplicationFilters
> {
    itemPathKey = 'agentLoanApplicationId';
    itemDetailsFormKey =
        'embedded:deployment:forms/ap/ap-08-loan-application-info.json';

    api: BasicStoreApi<LoanApplicationBorrowerBrief> = {
        loadList: async () => {
            const response = await RequestHelper.unwrapFromAxiosPromise(
                agentLoanApplicationsApi.findAllAgentApplications(
                    this.pager?.page,
                    this.pager?.size,
                    InitialFilters.defaultSort,
                    this.filterCriteria.filter.status,
                    this.filterCriteria.filter.issueDateFrom?.toISOString(true),
                    this.filterCriteria.filter.issueDateTo?.toISOString(),
                    this.filterCriteria.filter.search,
                ),
            );

            this.pager.setTotal(response.totalElements || 0);

            return response.content || [];
        },
        loadItem: (id) => {
            return RequestHelper.unwrapFromAxiosPromise(
                agentLoanApplicationsApi.getAgentApplication(id),
            );
        },
        loadItemForm: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(this.itemDetailsFormKey),
            );
        },
    };

    filterCriteria = new FilterCriteria<LoanApplicationFilters>();
    pager = new Pager();
}

export const AgentLoanApplicationsStore = new AgentLoanApplicationsStoreClass();
