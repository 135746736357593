import { action, computed, observable } from 'mobx';
import { TaskRepresentation } from 'src/generated-api-client';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { TaskWithActionStore } from 'src/stores/TaskWithActionStore/TaskWithActionStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';

export class TaskWithActionViewModelClass {
    @observable taskDefinition?: TaskRepresentation;

    @action async loadItem(id: string) {
        await Promise.all([
            TaskWithActionStore.loadItem(id),
            TaskWithActionStore.loadForm(id),
            TaskWithActionStore.loadTaskDefinitionItem(id),
        ]);

        this.setCurrentTask(id);
    }

    @action setCurrentTask(id: string) {
        this.taskDefinition = TaskWithActionStore.getTaskById(
            id,
        ) as TaskRepresentation;
    }

    @computed get taskVariables() {
        return TaskWithActionStore.currentItem
            ? { data: TaskWithActionStore.currentItem }
            : undefined;
    }

    @computed get currentTaskDefinition() {
        return this.taskDefinition;
    }

    @computed get taskForm() {
        return TaskWithActionStore.currentItemForm;
    }

    @computed get loadingStatus() {
        return new CombinedLoadingStatus([
            TaskWithActionStore.itemLoader,
            TaskWithActionStore.formLoader,
            TaskWithActionStore.listLoader,
        ]);
    }

    @action async submitForm(id: string, values: any) {
        const data = await TaskWithActionStore.submitForm(id, values);
        await AssignedTasksStore.loadList();

        return data;
    }

    @action reset() {
        TaskWithActionStore.reset();
        this.taskDefinition = undefined;
    }
}

export const TaskWithActionViewModel = new TaskWithActionViewModelClass();
