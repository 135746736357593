export const FullNameHelper = (entity: {
    lastName?: string;
    firstName?: string;
    middleName?: string;
    fullName?: string;
}) => {
    const fullNamePerson = [entity?.firstName, entity?.lastName]
        .filter((part) => Boolean(part))
        .join(' ');

    return fullNamePerson || entity?.fullName;
};
