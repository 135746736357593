import { Utils } from 'formiojs';
import { form as template } from 'src/formio/templates/input/form';
import { getDisabledValue } from 'src/formio/utils/getDisabledValue';

const compiledForm = Utils.Evaluator.template(template, 'input');
export const formioInputComponentCustomTemplate = (ctx: any) => {
    return compiledForm({
        ...ctx,
        disabledValue: getDisabledValue(ctx),
    });
};
