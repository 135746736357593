import { Divider } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { TaskRepresentation } from 'src/generated-api-client';
import { useAvailableTasksInterval } from 'src/hooks/useAvailableTasksInterval';
import { TaskListItem } from 'src/pages/TasksPage/TaskListItem';
import { TaskView } from 'src/pages/TasksPage/TaskView';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { AvailableTasksStore } from 'src/stores/AvailabaleTasksStore/AvailableTasksStore';
import { TasksStore } from 'src/stores/TasksStore/TasksStore';
import styled from 'styled-components';

const pageName = 'tasks';

export const TasksPage = observer(() => {
    useEffect(() => {
        TasksStore.loadList();
    }, []);

    useAvailableTasksInterval();

    return (
        <MasterDetailsPage
            pageName={pageName}
            store={TasksStore}
            asidePanel={<Aside />}
        >
            <TaskView />
        </MasterDetailsPage>
    );
});

const Aside = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledAsideWrapper>
            <StyledHeader>
                {t('Page.Tasks.AsidePanel.Assigned.Title')}{' '}
            </StyledHeader>
            <StyledListWrapper>
                <MasterDetailsAside
                    store={AssignedTasksStore}
                    renderItem={(item: TaskRepresentation) => {
                        return <TaskListItem key={item.id} item={item} />;
                    }}
                />
            </StyledListWrapper>
            <StyledDivider />
            <StyledHeader>
                {t('Page.Tasks.AsidePanel.Available.Title')}
            </StyledHeader>
            <StyledListWrapper>
                <MasterDetailsAside
                    store={AvailableTasksStore}
                    renderItem={(item: TaskRepresentation) => {
                        return <TaskListItem key={item.id} item={item} />;
                    }}
                />
            </StyledListWrapper>
        </StyledAsideWrapper>
    );
});

const StyledAsideWrapper = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
`;

const StyledListWrapper = styled.div`
    position: relative;
    overflow: auto;
    min-height: 20%;
    max-height: 70%;
`;

const StyledHeader = styled.span`
    font-size: 1.05rem;
    line-height: 135%;

    color: var(--text-color);

    padding: 0.88rem 1.3rem 0.88rem 2.2rem;
    margin-bottom: 0;
`;

const StyledDivider = styled(Divider)`
    border-top: 1px solid #d2d2d7;
    width: 90%;
    min-width: 90%;
    margin: 0 1.2rem;
`;
