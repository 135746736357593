import moment, { Moment } from 'moment';
import { getDateFormatForLocales } from 'src/formio/components/Datetime/utils';

const convertToServerDate = (value: Moment | string) => {
    return format(value, 'YYYY-MM-DD');
};

const format = (
    value: Moment | string,
    format = getDateFormatForLocales().appFormat,
) => {
    const mValue = moment.isMoment(value) ? value : moment(value);

    return mValue.format(format);
};

const formatDate = (value: Moment | string) => {
    return format(value);
};

const formatDateTime = (value: Moment | string) => {
    return format(value, 'MM/DD/YYYY h:mm a');
};

export const DateHelper = {
    convertToServerDate,
    format,
    formatDate,
    formatDateTime,
};
