import { action } from 'mobx';
import { taskManagerApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/BasicStore/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class CurrentTaskStoreClass extends BasicStore<Record<string, any>> {
    itemDetailsFormKey = '';
    itemPathKey = 'taskId';

    api: BasicStoreApi<Record<string, any>> = {
        loadItem: (id: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                taskManagerApi.getTaskFormVariables(id),
            );
        },

        loadItemForm: (key: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                taskManagerApi.getTaskForm(key),
            );
        },
    };

    claimTaskAction = new AsyncOperationWithStatus((taskId: string) =>
        RequestHelper.unwrapFromAxiosPromise(taskManagerApi.claim(taskId)),
    );

    unclaimTaskAction = new AsyncOperationWithStatus((taskId: string) =>
        RequestHelper.unwrapFromAxiosPromise(taskManagerApi.unclaim(taskId)),
    );

    submitFormLoader = new AsyncOperationWithStatus(
        (taskId: string, values: Record<string, any>) =>
            RequestHelper.unwrapFromAxiosPromise(
                taskManagerApi.submitForm(taskId, values),
            ),
    );

    @action reset() {
        this.itemLoader.reset();
        this.formLoader.reset();
    }

    @action async claim(id: string) {
        await this.claimTaskAction.call(id);
    }

    @action async unclaim(id: string) {
        await this.unclaimTaskAction.call(id);
    }

    @action submitForm(taskId: string, values: any) {
        return this.submitFormLoader.call(taskId, values);
    }
}

export const CurrentTaskStore = new CurrentTaskStoreClass();
