import { observer } from 'mobx-react-lite';
import { BasePage } from 'src/components/BasePage';
import { EntityPresentationProfile } from 'src/pages/AgentProfilePage/EntityPresentationProfile';
import { AgentProfileStore } from 'src/stores/AgentProfileStore/AgentProfileStore';
import styled from 'styled-components';

const store = AgentProfileStore;

export const AgentProfilePage = observer(() => {
    return (
        <StyledProfileBasePage pageName="agentProfile">
            <EntityPresentationProfile
                store={store}
                entityName="agentProfile"
            />
        </StyledProfileBasePage>
    );
});

const StyledProfileBasePage = styled(BasePage)`
    margin-top: 1rem;
    .formio-component-htmlelement {
        h2 {
            padding: 0;
        }
    }
    .ant-layout-content {
        min-height: 100vh;
    }
`;
