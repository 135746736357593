import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { BasePage, PageType } from 'src/components/BasePage';
import { EntityProfileDetails } from 'src/pages/AgentProfilePage/EntityProfileDetails';
import { EntityProfileEdit } from 'src/pages/AgentProfilePage/EntityProfileEdit';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

export type EntityPresentationProfileProps = {
    store: BasicStore<any>;
    entityName: string;
};
export const EntityPresentationProfile = observer(
    ({ store, entityName }: EntityPresentationProfileProps) => {
        const { path, url } = useRouteMatch();

        return (
            <StyledPage pageName={entityName} type={PageType.FULL}>
                <Switch>
                    {store.itemDetailsFormKey && (
                        <Route
                            path={`${path}/:entityId`}
                            exact
                            render={() => (
                                <EntityProfileDetails store={store} />
                            )}
                        />
                    )}
                    {store.itemEditFormKey && (
                        <Route
                            path={`${path}/:entityId/edit`}
                            exact
                            render={() => (
                                <EntityProfileEdit
                                    store={store}
                                    entityListUrl={url}
                                />
                            )}
                        />
                    )}
                    <Route
                        path={`${path}/*`}
                        exact
                        render={() => <Redirect to={path} />}
                    />
                </Switch>
            </StyledPage>
        );
    },
);

const StyledPage = styled(BasePage)`
    padding: 2rem 1.82rem 2rem 3.7rem;
    .ant-list {
        margin-left: 1.18rem;
    }
`;
