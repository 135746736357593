import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { Loan } from 'src/generated-api-client';
import { LoanListItem } from 'src/pages/LoansPage/LoanListItem';
import { LoansHeaderPanel } from 'src/pages/LoansPage/LoansFilterPanel';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';

const store = LoansStore;

export const LoansPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="loans"
            store={store}
            asidePanel={<LoansAsidePanel />}
        >
            <EntityPresentation store={store} entityName="loan" />
        </MasterDetailsPage>
    );
});

const LoansAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: Loan) => {
                return (
                    <List.Item key={item.id}>
                        <LoanListItem item={item} />
                    </List.Item>
                );
            }}
            header={<LoansHeaderPanel />}
        />
    );
});
