/* eslint-disable max-lines */
import { Form, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'src/components/DateRangePicker/DateRangePicker';
import { SearchPanel } from 'src/components/SearchPanel/SearchPanel';
import {
    SearchPanelFilterForm,
    SearchPanelFilterFormProps,
} from 'src/components/SearchPanel/SearchPanelFilterForm';
import { getTransactionStatuses } from 'src/pages/TransactionsPage/TransactionStatusInfoMap';
import {
    TransactionsFilter,
    TransactionsStore,
} from 'src/stores/TransactionsStore/TransactionsStore';
import { TransactionsTypes } from 'src/stores/TransactionsStore/TransactionsStore.types';
import styled from 'styled-components';

const store = TransactionsStore;

export const TransactionsHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <SearchPanel
            store={store}
            renderPanel={(props) => <FilterPanel {...props} />}
            placeholder={t('Page.Transactions.AsideHeader.Search.Placeholder', {
                defaultValue: 'Loan, ID or Borrower',
            })}
        />
    );
});

const FilterPanel = observer((props: SearchPanelFilterFormProps) => {
    const statuses = getTransactionStatuses();

    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{ createdAt: [] }}
            onFinish={(values: TransactionsFilter) => {
                const { createdAt, processedAt, type, status, search } = values;
                store.pager.reset();
                const [createdAtFrom, createdAtTo] = createdAt || [
                    undefined,
                    undefined,
                ];
                createdAtFrom?.set('hour', 0).set('minute', 0).set('second', 0);
                createdAtTo
                    ?.set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59);
                const [processedAtFrom, processedAtTo] = processedAt || [
                    undefined,
                    undefined,
                ];
                processedAtFrom
                    ?.set('hour', 0)
                    .set('minute', 0)
                    .set('second', 0);
                processedAtTo
                    ?.set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59);
                store.filterCriteria?.applyFilter({
                    status,
                    type,
                    search,
                    createdAtFrom,
                    createdAtTo,
                    processedAtFrom,
                    processedAtTo,
                });
                store.loadList();
            }}
        >
            <StyledSelect
                label={t('Page.Transactions.Filter.Status', {
                    defaultValue: 'Status',
                })}
                name="status"
            >
                <Select mode="multiple" allowClear>
                    {statuses.map((status) => (
                        <Select.Option key={status.id} value={status.id}>
                            {t(`data:status.transaction.${status.id}`)}
                        </Select.Option>
                    ))}
                </Select>
            </StyledSelect>

            <StyledSelect
                label={t('Page.Transactions.Filter.Type', {
                    defaultValue: 'Type',
                })}
                name="type"
            >
                <Select mode="multiple" allowClear>
                    <Select.Option value={TransactionsTypes.Repayment}>
                        {t('Page.Transactions.Filter.Type.Repayment', {
                            defaultValue: TransactionsTypes.Repayment,
                        })}
                    </Select.Option>
                    <Select.Option value={TransactionsTypes.Disbursement}>
                        {t('Page.Transactions.Filter.Type.Disbursement', {
                            defaultValue: TransactionsTypes.Disbursement,
                        })}
                    </Select.Option>
                </Select>
            </StyledSelect>
            <Form.Item
                label={t('Page.Transactions.Filter.Type.CreatedAt')}
                name="createdAt"
            >
                <DateRangePicker />
            </Form.Item>
            <Form.Item
                label={t('Page.Transactions.Filter.Type.ProcessedAt')}
                name="processedAt"
            >
                <DateRangePicker />
            </Form.Item>
        </SearchPanelFilterForm>
    );
});

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        min-height: 2.7rem;
        border-radius: 0.5rem;
    }
`;
