import _ from 'lodash';
import { action, computed, observable, when } from 'mobx';
import { TaskRepresentation } from 'src/generated-api-client';
import { FormDefinition } from 'src/services/api.types';
import { taskManagerApi } from 'src/services/apiServices';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { TasksStore } from 'src/stores/TasksStore/TasksStore';
import { AsyncOperationWithStatus } from 'src/utils/BasicStore/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

interface TaskExtensions {
    urgentTask?: string;
}

export class TaskStoreClass extends BasicStore<TaskRepresentation> {
    api: BasicStoreApi<TaskRepresentation> = {};
    itemDetailsFormKey = '';
    @observable currentTask?: TaskRepresentation;
    @observable hasUrgentTask = false;
    @observable urgentTaskId = '';
    @observable assignedTaskLoaded = false;

    // formLoader = new AsyncOperationWithStatus((taskId: string) =>
    //     RequestHelper.unwrapFromAxiosPromise(
    //         taskManagerApi.getTaskForm(taskId),
    //     ),
    // );

    initialValuesLoader = new AsyncOperationWithStatus((taskId: string) =>
        RequestHelper.unwrapFromAxiosPromise(
            taskManagerApi.getTaskFormVariables(taskId),
        ),
    );

    submitFormLoader = new AsyncOperationWithStatus(
        (taskId: string, values: Record<string, any>) =>
            RequestHelper.unwrapFromAxiosPromise(
                taskManagerApi.submitForm(taskId, values),
            ),
    );

    claimTaskAction = new AsyncOperationWithStatus((taskId: string) =>
        RequestHelper.unwrapFromAxiosPromise(taskManagerApi.claim(taskId)),
    );

    unclaimTaskAction = new AsyncOperationWithStatus((taskId: string) =>
        RequestHelper.unwrapFromAxiosPromise(taskManagerApi.unclaim(taskId)),
    );

    assignedTaskListLoader = new AsyncOperationWithStatus(() =>
        RequestHelper.unwrapFromAxiosPromise(taskManagerApi.listAssigned()),
    );

    @computed get assignedTaskList() {
        return this.assignedTaskListLoader.data;
    }

    @computed get currentForm() {
        return this.formLoader.data as FormDefinition;
    }

    @computed get currentFormInitialValues() {
        return { data: { ...this.initialValuesLoader.data } };
    }

    // @action
    // async loadForm(taskId: string) {
    //     await Promise.all([
    //         this.formLoader.call(taskId),
    //         this.initialValuesLoader.call(taskId),
    //     ]);
    // }

    getUrgentTask(tasksList: TaskRepresentation[]) {
        return tasksList?.find(
            (task) =>
                _.has(task?.extensions, 'urgentTask') &&
                (task?.extensions as unknown as TaskExtensions)?.urgentTask ===
                    'true',
        );
    }

    @action async loadAssignedTaskList() {
        await when(() => AuthStore.authenticated);
        await this.assignedTaskListLoader.call();
        this.assignedTaskLoaded = true;
        if (!this.assignedTaskListLoader.hasError) {
            const tasksList = this.assignedTaskListLoader.data?.content || [];
            const urgentTask = this.getUrgentTask(tasksList);

            if (urgentTask) {
                this.hasUrgentTask = true;
                this.urgentTaskId = urgentTask?.id;
            }
        }
    }

    @action async submitForm(taskId: string, values: any) {
        const data = await this.submitFormLoader.call(taskId, values);
        TasksStore.loadList();

        return data;
    }

    @action async loadCurrentTask(taskId: string) {
        if (!taskId) {
            this.currentTask = undefined;
        }
        await TasksStore.loadItem(taskId);
        const task = TasksStore.getTaskById(taskId);
        this.currentTask = task;
        if (task) {
            this.loadForm();
        }
    }

    // private getAssignedTask(taskId: string) {
    //     return this.assignedTaskList?.find((x) => x.id === taskId);
    // }

    @action resetUrgentTask() {
        this.urgentTaskId = '';
        this.assignedTaskLoaded = true;
        this.hasUrgentTask = false;
    }

    @action async claimTask(taskId: string) {
        await this.claimTaskAction.call(taskId);

        return !this.claimTaskAction.hasError;
    }

    @action async unclaimTask(taskId: string) {
        await this.unclaimTaskAction.call(taskId);

        return !this.unclaimTaskAction.hasError;
    }

    @computed get getUrgentTaskId() {
        return this.urgentTaskId;
    }

    @computed get isHasUrgentTask() {
        return this.hasUrgentTask;
    }
}

export const TaskStore = new TaskStoreClass();
