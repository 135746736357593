import { Button, Form, FormInstance, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export type SearchPanelFilterFormProps<ValuesType = any> = {
    form?: FormInstance<ValuesType>;
    closePopper?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onFinish?: (values: ValuesType) => void | Promise<void>;
    initialValues?: Record<string, any>;
};
export const SearchPanelFilterForm: React.FC<SearchPanelFilterFormProps> =
    observer(({ form, closePopper, onFinish, initialValues, children }) => {
        const { t } = useTranslation();

        return (
            <StyledWrapper>
                <Form<{ status?: string; issuedAt?: [] }>
                    form={form}
                    layout="vertical"
                    initialValues={initialValues}
                    onFinish={async (values) => {
                        await onFinish?.(values);
                        closePopper?.();
                    }}
                    onReset={() => {
                        form?.resetFields();
                        form?.submit();
                    }}
                >
                    {children}
                    <Row justify="space-between">
                        <StyledApplyButton htmlType="submit">
                            {t('Component.AsidePanel.Filter.Apply')}
                        </StyledApplyButton>
                    </Row>
                </Form>
            </StyledWrapper>
        );
    });

const StyledApplyButton = styled(Button)`
    width: 100%;
    background: var(--btn-bg);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    &:hover {
        background: var(--btn-bg-hover);
        color: white;
    }
`;

const StyledWrapper = styled.div`
    width: 19rem;
    position: relative;

    .ant-form-item-label {
        padding: 0 0 0.5rem;

        label {
            font-size: 1.1rem;
            color: var(--label-color);
            font-weight: 600;
        }
    }

    .ant-select-selector {
        border-radius: 0.3rem;
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: var(--input-border-color-focus) !important;
        border-width: 0.15rem;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-color: var(--input-border-color-focus) !important;
        border-width: 0.15rem;
        box-shadow: none;
    }

    .ant-picker-range {
        border-radius: 0.47rem;
        width: 100%;
        height: 2.7rem;
    }
    .ant-form-item {
        margin-bottom: 1.25rem;
    }

    .ant-btn {
        border-radius: 0.3rem;
    }

    .ant-btn-primary {
        background: #0a65ed;
        border-radius: 0.47rem;
        font-weight: 700;
        color: white;
    }
`;
