import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { CollectionBrief } from 'src/generated-api-client';
import { CollectionsHeaderPanel } from 'src/pages/CollectionsPage/CollectionsHeaderPanel';
import { CollectionListItem } from 'src/pages/CollectionsPage/CollectionsListItem';
import { CollectionsStore } from 'src/stores/CollectionsStore/CollectionsStore';

const store = CollectionsStore;

export const CollectionsPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="collections"
            store={store}
            asidePanel={<CollectionsAsidePanel />}
        >
            <EntityPresentation store={store} entityName="collection" />
        </MasterDetailsPage>
    );
});

const CollectionsAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: CollectionBrief) => {
                return (
                    <List.Item key={item.id}>
                        <CollectionListItem item={item} />
                    </List.Item>
                );
            }}
            header={<CollectionsHeaderPanel />}
        />
    );
});
