import Components from 'formiojs/components/Components';

const UrlfieldComponent = (Components as any).components.url;

class UrlField extends (UrlfieldComponent as any) {
    setValue(value: any, flags: any = {}) {
        this.redraw();

        return super.setValue(value, flags);
    }

    attach(element: any) {
        return super.attach(element);
    }
}

export default UrlField;
