/* eslint-disable max-lines */
import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SearchPanel } from 'src/components/SearchPanel/SearchPanel';
import {
    SearchPanelFilterForm,
    SearchPanelFilterFormProps,
} from 'src/components/SearchPanel/SearchPanelFilterForm';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import { AgentsFilter, AgentsStore } from 'src/stores/AgentsStore/AgentsStore';
import { UserStore } from 'src/stores/UserStore/UserStore';
import { CssColors } from 'src/styles/CssVariables';
import styled from 'styled-components';

const store = AgentsStore;

export const AgentsPageHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <SearchPanel
                store={store}
                renderPanel={(props) => <FilterPanel {...props} />}
                placeholder={t('Page.Agents.Filter.Search.Placeholder')}
            />
            {UserStore.can.manage.agents && (
                <Tooltip
                    title={t('Page.Agents.AsideHeader.CreateNewItem.Tooltip', {
                        defaultValue: 'Add new merchant',
                    })}
                >
                    <StyledCreateButton
                        onClick={() => {
                            const createLink = EntityLinks.agents.create?.();
                            createLink && Router.navigate(createLink);
                        }}
                    >
                        <PlusOutlined />
                    </StyledCreateButton>
                </Tooltip>
            )}
        </StyledHeaderWrapper>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
`;

const StyledCreateButton = styled.button`
    width: 3.6rem;
    min-width: 3rem;
    font-weight: 700;
    height: 2.4rem;
    margin-left: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 2rem;
    border: none;
    background: ${CssColors.black};
    border-radius: 0.35rem;
    color: #ffffff;
    span: {
        font-size: 1rem;
    }
`;

const FilterPanel = observer((props: SearchPanelFilterFormProps) => {
    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{ issuedAt: [] }}
            onFinish={({ ...values }: AgentsFilter) => {
                store.filterCriteria?.applyFilter({
                    ...values,
                });
                store.loadList();
            }}
        >
            <Form.Item
                label={t('Page.Agents.Filter.TaxId', {
                    defaultValue: 'Federal tax id',
                })}
                name="taxId"
            >
                <StyledInput type="string" />
            </Form.Item>
        </SearchPanelFilterForm>
    );
});

const StyledInput = styled(Input)`
    height: 2.7rem;
    border-radius: 0.5rem;
`;
