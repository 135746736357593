import { observer } from 'mobx-react-lite';
import { FormForTaskWithAction } from 'src/components/FormForTaskWithAction/FormForTaskWithAction';
import { useAssignedTasksInterval } from 'src/hooks/useAssignedTasksInterval';
import { TaskWithActionStore } from 'src/stores/TaskWithActionStore/TaskWithActionStore';

export const FormWrapperForTaskWithAction = observer(() => {
    useAssignedTasksInterval();
    const taskId = TaskWithActionStore.taskWithAction?.id || '';

    if (!taskId) {
        return null;
    }

    return <FormForTaskWithAction taskId={taskId} />;
});
