import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ProcessStartForm } from 'src/components/ProcessStartForm';
import { Spinner } from 'src/components/Spinner/Spinner';
import { Router } from 'src/core/router/Router';
import { ProcessStoreProvider } from 'src/stores/ProcessStore/ProcessStore';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import styled from 'styled-components';

export type EntityProcessProps = {
    store: BasicStore<any>;
    entityListUrl: string;
};

type EntityProcessRouteMath = { entityId: string; processKey: string };

export const EntityProcess = observer(
    ({ store, entityListUrl }: EntityProcessProps) => {
        const { params } = useRouteMatch<EntityProcessRouteMath>();
        const { entityId, processKey } = params;
        const process = ProcessStoreProvider.getInstance(processKey);

        const processContext = process.hasContext
            ? process.getContext
            : store.currentItem;

        useEffect(() => {
            if (!entityId || !process) {
                return;
            }
            store.loadItem(entityId);

            return () => {
                process.clearContext();
            };
        }, [entityId]);

        const entityUrl = `${entityListUrl}/${entityId}`;

        const loadingStatus = useMemo(() => {
            return new CombinedLoadingStatus([store.itemLoader]);
        }, []);

        if (loadingStatus.isLoading) {
            return <StyledSpinner />;
        }

        return (
            <ProcessStartForm
                processKey={processKey}
                key={`${entityId}-process`}
                context={processContext}
                onSubmit={() => {
                    store.loadList();
                    Router.navigate(entityUrl);
                }}
                onCustomEvent={(event) => {
                    if (event.type === 'navigateToEntityCard') {
                        event.event.preventDefault();
                        Router.navigate(entityUrl);
                    }
                }}
            />
        );
    },
);

const StyledSpinner = styled(Spinner)`
    margin: 0 auto;
    display: block;
`;
