// eslint-disable-next-line simple-import-sort/sort
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { FormioEvent } from 'src/core/Formio.types';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import styled from 'styled-components';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { TaskWithActionViewModel } from 'src/components/FormForTaskWithAction/TaskWithActionViewModel';
import { TaskActionManager } from 'src/stores/TaskWithActionStore/TaskActionManager';

export const FormForTaskWithAction = observer(
    ({ taskId }: { taskId: string }) => {
        const [formInstance, setFormInstance] = useState(null);

        useEffect(() => {
            if (!taskId) return;

            TaskWithActionViewModel.loadItem(taskId);

            return () => {
                TaskWithActionViewModel.reset();
                setFormInstance(null);
            };
        }, [taskId]);

        const submitHandler = useMemo(() => {
            return getSubmitHandler(taskId);
        }, [taskId]);

        useEffect(() => {
            if (
                !formInstance ||
                !TaskWithActionViewModel.taskVariables ||
                !TaskWithActionViewModel.currentTaskDefinition
            ) {
                return;
            }

            TaskActionManager.taskActionExecutor(
                TaskWithActionViewModel,
                formInstance,
            );
        }, [
            formInstance,
            TaskWithActionViewModel.taskVariables,
            TaskWithActionViewModel.currentTaskDefinition,
        ]);

        return (
            <StyledComponentWrapper>
                {taskId ? (
                    <FormioForm
                        getForm={setFormInstance}
                        form={TaskWithActionViewModel.taskForm as any}
                        submission={TaskWithActionViewModel.taskVariables}
                        onSubmit={submitHandler as any}
                        normalizeSubmission
                    />
                ) : null}
            </StyledComponentWrapper>
        );
    },
);

const getSubmitHandler = (taskId: string) => {
    let isSubmitting = false;

    return async (values: FormioEvent) => {
        if (isSubmitting) {
            return;
        }
        isSubmitting = true;

        const { data } = values;
        await submitForm(taskId, data);
    };
};

const submitForm = async (taskId: string, data: any) => {
    await TaskWithActionViewModel.submitForm(taskId, data);
    TaskWithActionViewModel.reset();
    AssignedTasksStore.loadList();
};

const StyledComponentWrapper = styled.div`
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    z-index: -1;
    position: fixed;
`;
