import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import _ from 'lodash';
import { initReactI18next } from 'react-i18next';
import { Env } from 'src/core/Environment';
import { UiConfigurations } from 'src/generated-api-client';

export const FALLBACK_LOCALE = 'en';
export const FALLBACK_MISSING_KEY_VALUE = '';

export type WeblateOptions = {
    addPath?: string;
};

export type InitInternationalizationOptions = UiConfigurations & {
    webplate?: WeblateOptions;
};

const appNamespace = 'override-translation';
const fallbackNamespace = [appNamespace, 'translation'];
const namespaces = [
    'bo',
    'ap',
    'components',
    'data',
    'processes',
    'custom',
    appNamespace,
    ...fallbackNamespace,
];

const namespacesMap = new Map<string, boolean>(
    namespaces.map((ns) => [ns, true]),
);

const formTranslationResourcesPath = '/api/deployment/resource/l10n';
const formTranslationResourcesOverridePath =
    '/api/deployment/resource/l10n-override';

export const initInternationalization = async ({
    webplate,
    ...settings
}: InitInternationalizationOptions) => {
    await i18next
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            fallbackLng: FALLBACK_LOCALE, // if you're using a language detector, do not define the lng option
            debug: Env.isDev,
            interpolation: {
                escapeValue: false,
            },
            returnEmptyString: false,
            ns: namespaces,
            defaultNS: appNamespace,
            fallbackNS: fallbackNamespace,
            backend: {
                loadPath: ([lng]: string[], [ns]: string[]) => {
                    if (!fallbackNamespace.includes(ns)) {
                        return `${formTranslationResourcesPath}/${ns}/${lng}.json`;
                    }

                    return `/l10n/${ns}/${lng}.json`;
                },
                // eslint-disable-next-line max-params
                request: async (options, url, payload, callback) => {
                    try {
                        const overriddenResourceUrl = url.replace(
                            formTranslationResourcesPath,
                            formTranslationResourcesOverridePath,
                        );
                        const [overriddenResource, originResource] =
                            await Promise.allSettled([
                                await fetch(overriddenResourceUrl),
                                await fetch(url),
                            ]);

                        const overriddenData = await getDataFromResponse(
                            overriddenResource,
                        );

                        const originData = await getDataFromResponse(
                            originResource,
                        );
                        // eslint-disable-next-line callback-return
                        callback(undefined, {
                            status: 200,
                            data: _.merge({}, originData, overriddenData),
                        });
                    } catch (error) {
                        // eslint-disable-next-line callback-return
                        callback({}, { status: 404, data: '' });
                    }
                },
                // addPath: webplate?.addPath,
            },
            missingKeyHandler(_lngs, ns) {
                if (namespacesMap.has(ns) || namespacesMap.get(ns)) {
                    return;
                }
                namespacesMap.set(ns, false);

                i18next
                    .loadNamespaces(ns)
                    .then(() => namespacesMap.set(ns, true));
            },
            parseMissingKeyHandler(key) {
                if (isTranslationKeyHasUndefined(key)) {
                    return Env.isDev
                        ? `MISSING KEY (FOR DEV)${key}`
                        : FALLBACK_MISSING_KEY_VALUE;
                }

                return key;
            },
            // saveMissing: true, // Boolean(weblate?.addPath),
            detection: {
                order: [
                    'querystring',
                    'cookie',
                    'localStorage',
                    'sessionStorage',
                    'htmlTag',
                    'path',
                    'subdomain',
                ],
            },
            partialBundledLanguages: true,
            resources: {},
        });

    createVirtualEnvNamespaceBasedOnUiConfiguration(settings);

    // HACK: Formio.js uses the flag initialized to detect initialized i18next or not, but i18next has only flag isInitialized
    // see link: https://github.com/formio/formio.js/blob/4ae669a2ceda00b71e32e8cc1f9b7dfdaba0c822/src/Webform.js#L335
    (i18next as any).initialized = i18next.isInitialized;
};

function createVirtualEnvNamespaceBasedOnUiConfiguration(
    settings: UiConfigurations,
) {
    settings.supportedLocales?.forEach((lng) => {
        i18next.addResources(lng, 'env', settings.env);
    });
}

export default i18next;

async function getDataFromResponse(response: PromiseSettledResult<Response>) {
    return response.status === 'fulfilled' &&
        (response.value.status === 200 || response.value.status === 304)
        ? // eslint-disable-next-line no-return-await
          await response.value.json()
        : {};
}

function isTranslationKeyHasUndefined(key: string): boolean {
    return key.includes('undefined');
}
