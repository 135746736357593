import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { LoanProduct } from 'src/generated-api-client';
import { ProductsHeaderPanel } from 'src/pages/ProductsPage/ProductsHeaderPanel';
import { ProductListItem } from 'src/pages/ProductsPage/ProductsListItem';
import { ProductsStore } from 'src/stores/ProductsStore/ProductsStore';

const store = ProductsStore;

export const ProductsPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="products"
            store={store}
            asidePanel={<ProductsAsidePanel />}
        >
            <EntityPresentation store={store} entityName="product" />
        </MasterDetailsPage>
    );
});

const ProductsAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: LoanProduct) => {
                return (
                    <List.Item key={item.id}>
                        <ProductListItem item={item} />
                    </List.Item>
                );
            }}
            header={<ProductsHeaderPanel />}
        />
    );
});
