/* eslint-disable max-lines */
import { Form, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'src/components/DateRangePicker/DateRangePicker';
import { SearchPanel } from 'src/components/SearchPanel/SearchPanel';
import {
    SearchPanelFilterForm,
    SearchPanelFilterFormProps,
} from 'src/components/SearchPanel/SearchPanelFilterForm';
import { getLoanStatuses } from 'src/pages/LoansPage/LoanStatusInfoMap';
import { AgentLoansStore } from 'src/stores/AgentLoansStore/AgentLoansStore';
import styled from 'styled-components';

const store = AgentLoansStore;
const i18Namespace = '';

export const AgentLoansHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <SearchPanel
            store={store}
            placeholder={t('Page.Agent.Loans.Filter.Search.Placeholder')}
            renderPanel={(props) => <FilterPanel {...props} />}
        />
    );
});

const FilterPanel = observer((props: SearchPanelFilterFormProps) => {
    const statuses = getLoanStatuses();
    const { t } = useTranslation(i18Namespace);

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{ issuedAt: [] }}
            onFinish={(values) => {
                const { issuedAt, status } = values;
                store.pager.reset();
                const [issueDateFrom, issueDateTo] = issuedAt || [
                    undefined,
                    undefined,
                ];
                issueDateFrom?.set('hour', 0).set('minute', 0).set('second', 0);
                issueDateTo
                    ?.set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59);
                store.filterCriteria?.applyFilter({
                    status: status && status.length > 0 ? status : undefined,
                    issueDateFrom,
                    issueDateTo,
                });
                store.loadList();
            }}
        >
            <StyledSelect
                label={t('Page.AgentLoans.Filter.Status')}
                name="status"
            >
                <Select allowClear mode="multiple">
                    {statuses.map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                            {t(`data:status.loan.${item.id}`)}
                        </Select.Option>
                    ))}
                </Select>
            </StyledSelect>
            <Form.Item
                label={t('Page.AgentLoans.Filter.IssuedAt')}
                name="issuedAt"
            >
                <DateRangePicker
                    placeholder={[
                        t('Component.DateRangePicker.Start'),
                        t('Component.DateRangePicker.End'),
                    ]}
                />
            </Form.Item>
        </SearchPanelFilterForm>
    );
});

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        min-height: auto;
        border-radius: 0.5rem;
    }
`;
