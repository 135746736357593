import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { Loan } from 'src/generated-api-client';
import { AgentLoanListItem } from 'src/pages/AgentLoansPage/AgentLoanListItem';
import { AgentLoansHeaderPanel } from 'src/pages/AgentOperationsPage/AgentLoansFilterPanel';
import { AgentLoansStore } from 'src/stores/AgentLoansStore/AgentLoansStore';
import { AgentOperationsStore } from 'src/stores/AgentOperationsStore/AgentOperationsStore';

export const AgentOperationsPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="operations"
            store={AgentLoansStore}
            asidePanel={<OperationsAgentAsidePanel />}
        >
            <EntityPresentation
                store={AgentOperationsStore}
                entityName="operations"
            />
        </MasterDetailsPage>
    );
});

const OperationsAgentAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={AgentLoansStore}
            renderItem={(item: Loan) => {
                return (
                    <List.Item key={item.id}>
                        <AgentLoanListItem item={item} />
                    </List.Item>
                );
            }}
            header={<AgentLoansHeaderPanel />}
        />
    );
});
