/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosPromise } from 'axios';
import { action, computed, observable } from 'mobx';

type Unwrap<T> = T extends Promise<infer U>
    ? U
    : T extends AxiosPromise<infer U>
    ? U
    : T;

export type AsyncOperationWithStatusOptions = {
    notifyIfError?: boolean;
};

export type AsyncFunction = (
    ...args: any[]
) => Promise<any> | AxiosPromise<any> | undefined;

export class AsyncOperationWithStatus<
    F extends AsyncFunction,
    DataType = Unwrap<ReturnType<F>>,
> {
    @observable isLoading = false;
    @observable error?: any;
    @observable isLoaded = false;
    @observable data?: DataType;
    @observable countLoads = 0;

    private silentMode = false;

    @computed get hasData() {
        return Boolean(this.data);
    }

    @computed get hasError() {
        return Boolean(this.error);
    }

    constructor(private fn: F) {}

    @action async call(...params: Parameters<F>) {
        try {
            if (!this.silentMode) {
                this.isLoading = true;
            }
            this.isLoaded = false;
            this.error = undefined;

            const data = (await this.fn.call(undefined, ...params)) as DataType;

            this.data = data;

            this.isLoaded = true;

            return data;
        } catch (error) {
            console.warn(error);
            this.error = error;
            this.isLoaded = false;
        } finally {
            this.countLoads++;
            this.isLoading = false;
        }
    }

    @action reset() {
        this.error = undefined;
        this.data = undefined;
        this.isLoading = false;
    }

    turnOnSilentMode() {
        this.silentMode = true;
    }

    turnOffSilentMode() {
        this.silentMode = false;
    }
}
