import { observer } from 'mobx-react-lite';
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import { BasePage, PageType } from 'src/components/BasePage';
import { EntityCreate } from 'src/components/EntityPresentation/EntityCreate';
import { EntityDetails } from 'src/components/EntityPresentation/EntityDetails';
import { EntityEdit } from 'src/components/EntityPresentation/EntityEdit';
import { EntityProcess } from 'src/components/EntityPresentation/EntityProcess';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

export type EntityPresentationProps = {
    store: BasicStore<any>;
    entityName: string;
};
export const EntityPresentation = observer(
    ({ store, entityName }: EntityPresentationProps) => {
        const { path, url } = useRouteMatch();
        const { pathname } = useLocation();

        return (
            <StyledPage pageName={entityName} type={PageType.FULL}>
                <Switch>
                    {store.itemCreateFormKey && (
                        <Route
                            path={`${path}/create`}
                            exact
                            render={() => (
                                <EntityCreate
                                    store={store}
                                    entityListUrl={url}
                                />
                            )}
                        />
                    )}
                    {store.itemDetailsFormKey && (
                        <Route
                            path={`${path}/:entityId`}
                            exact
                            render={() => <EntityDetails store={store} />}
                        />
                    )}
                    {store.itemEditFormKey && (
                        <Route
                            path={`${path}/:entityId/edit`}
                            exact
                            render={() => (
                                <EntityEdit store={store} entityListUrl={url} />
                            )}
                        />
                    )}
                    <Route
                        path={`${path}/:entityId/process/:processKey`}
                        exact
                        render={() => (
                            <EntityProcess store={store} entityListUrl={url} />
                        )}
                    />
                    {!pathname.match(/\d/) &&
                        Boolean(store?.list?.length > 0) && (
                            <Route
                                path={`${path}`}
                                exact
                                render={() => (
                                    <Redirect
                                        to={`${path}/${store?.list?.[0]?.id}`}
                                    />
                                )}
                            />
                        )}
                    <Route
                        path={`${path}/*`}
                        exact
                        render={() => <Redirect to={path} />}
                    />
                </Switch>
            </StyledPage>
        );
    },
);

const StyledPage = styled(BasePage)`
    padding: 2rem 1.82rem 2rem 3.7rem;
    .ant-list {
        margin-left: 1.18rem;
    }
`;
