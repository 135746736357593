import { computed } from 'mobx';
import { Agent } from 'src/generated-api-client';
import {
    agentProfileApi,
    bpmsResourcesManagerApi,
} from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class AgentProfileStoreClass extends BasicStore<Agent> {
    itemDetailsFormKey =
        'embedded:deployment:forms/ap/ap-02-agent-profile.json';

    itemEditFormKey =
        'embedded:deployment:forms/ap/ap-03-update-agent-profile.json';

    api: BasicStoreApi<Agent> = {
        loadItem: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                agentProfileApi.getAgentProfile(),
            );
        },
        loadItemForm: (formKey: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(formKey),
            );
        },
        updateItem: (_, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                agentProfileApi.updateAgent1(data),
            );
        },
    };

    @computed get currentAgentLogoUrl() {
        return this.currentItem?.logo?.[0]?.url;
    }

    @computed get currentAgentName() {
        return this.currentItem?.legalName;
    }
}

export const AgentProfileStore = new AgentProfileStoreClass();
