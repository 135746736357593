import {
    convertMapToList,
    DEFAULT_STATUS_KEY,
    StatusInfo,
} from 'src/components/EntityStatusInfo/EntityStatusInfo';
import { CssColors } from 'src/styles/CssVariables';

export const ProductsStatusInfoMap: Record<string, StatusInfo> = {
    ACTIVE: {
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        title: 'Active',
        backgroundColor: CssColors.mainGreen,
    },
    INACTIVE: {
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        title: 'Inactive',
        backgroundColor: CssColors.mainRed,
    },
    [DEFAULT_STATUS_KEY]: {
        color: '#91796c',
        borderColor: '#F4F0DF',
        title: 'Invalid status',
        backgroundColor: '#F4F0DF',
    },
};

export const getCollectionStatuses = () => {
    return convertMapToList(ProductsStatusInfoMap);
};
