import Components from 'formiojs/components/Components';

const EmailfieldComponent = (Components as any).components.email;

class EmailField extends (EmailfieldComponent as any) {
    setValue(value: any, flags: any = {}) {
        this.redraw();

        return super.setValue(value, flags);
    }

    attach(element: any) {
        return super.attach(element);
    }
}

export default EmailField;
