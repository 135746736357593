import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { AgentBrief } from 'src/generated-api-client';
import { AgentsListItem } from 'src/pages/AgentsPage/AgentsListItem';
import { AgentsPageHeaderPanel } from 'src/pages/AgentsPage/AgentsPageHeaderPanel';
import { AgentsStore } from 'src/stores/AgentsStore/AgentsStore';

const store = AgentsStore;

export const AgentsPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="agents"
            store={store}
            asidePanel={<AgentsAsidePanel />}
        >
            <EntityPresentation store={store} entityName="agents" />
        </MasterDetailsPage>
    );
});

const AgentsAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: AgentBrief) => {
                return (
                    <List.Item key={item.id}>
                        <AgentsListItem item={item} />
                    </List.Item>
                );
            }}
            header={<AgentsPageHeaderPanel />}
        />
    );
});
