/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { observer } from 'mobx-react-lite';
import { Spinner } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { IndexPageViewModel } from 'src/pages/IndexPage/IndexPageViewModel';

const viewModel = IndexPageViewModel;
export const IndexPage = observer(() => {
    if (!viewModel.loadingStatus.isLoaded) {
        return <Spinner animation="border" />;
    }

    return <Redirect to={viewModel.homePage} />;
});
