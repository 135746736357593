import { useEffect } from 'react';
import { TasksStore } from 'src/stores/TasksStore/TasksStore';

export const useAvailableTasksInterval = () => {
    useEffect(() => {
        const interval = window.setInterval(() => {
            TasksStore.hiddenLoadAvailableTasksList();
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, []);
};
