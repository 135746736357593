import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { Campaign } from 'src/generated-api-client';
import { CampaignsListItem } from 'src/pages/CampaignsPage/CampaignsListItem';
import { CampaignsPageHeaderPanel } from 'src/pages/CampaignsPage/CampaignsPageHeaderPanel';
import { CampaignsStore } from 'src/stores/CampaignsStore/CampaignsStore';

const store = CampaignsStore;

export const CampaignsPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="campaigns"
            store={store}
            asidePanel={<ProductsAsidePanel />}
        >
            <EntityPresentation store={store} entityName="campaigns" />
        </MasterDetailsPage>
    );
});

const ProductsAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: Campaign) => {
                return (
                    <List.Item key={item.id}>
                        <CampaignsListItem item={item} />
                    </List.Item>
                );
            }}
            header={<CampaignsPageHeaderPanel />}
        />
    );
});
