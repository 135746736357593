import { TaskWithActionViewModel } from 'src/components/FormForTaskWithAction/TaskWithActionViewModel';
import { TaskActionTypes } from 'src/stores/TaskWithActionStore/TaskActionTypes.types';
import { UrlHelper } from 'src/utils/UrlHelper';

const downloadFile = (
    taskViewModel: typeof TaskWithActionViewModel,
    formInstance: any,
) => {
    const tasVariables = taskViewModel.taskVariables?.data as {
        fileName: string;
        fileDownloadUrl: string;
    };

    const url = `/${tasVariables.fileDownloadUrl}`;

    UrlHelper.downloadFile(url, tasVariables.fileName);
    formInstance?.formio?.submit();
};

export const taskActionHandlers = {
    [TaskActionTypes.FILE_DOWNLOAD]: downloadFile,
};
