import { observer } from 'mobx-react-lite';
import { useRouteMatch } from 'react-router-dom';
import { ProcessStartForm } from 'src/components/ProcessStartForm';

type ProcessPageRouterParams = {
    processKey: string;
};
export const ProcessPage = observer(() => {
    const { params } = useRouteMatch<ProcessPageRouterParams>();

    return (
        <div className="container-fluid">
            <ProcessStartForm processKey={params.processKey} />
        </div>
    );
});
