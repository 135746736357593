import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import { Spinner } from 'src/components/Spinner/Spinner';
import { Router } from 'src/core/router/Router';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import styled from 'styled-components';

export type EntityEditProps = {
    store: BasicStore<any>;
    entityListUrl: string;
};
export const EntityEdit = observer(
    ({ store, entityListUrl }: EntityEditProps) => {
        const { params, url } = useRouteMatch<{ entityId: string }>();
        const { entityId } = params;

        const entityUrl = `${entityListUrl}/${entityId}`;

        useEffect(() => {
            if (!entityId) {
                return;
            }
            store.loadItem(entityId);
            store.loadForm(store.itemEditFormKey);
            store.updateItemLoader.reset();

            return () => {
                store.formLoader.reset();
            };
        }, [entityId]);

        const loadingStatus = useMemo(() => {
            return new CombinedLoadingStatus([
                store.formLoader,
                store.itemLoader,
            ]);
        }, []);

        if (loadingStatus.isLoading) {
            return <StyledSpinner />;
        }

        return (
            <FormioForm
                key={`${entityId}-edit`}
                form={store.currentItemForm as any}
                submission={{ data: store.currentItem } as any}
                onCustomEvent={(event) => {
                    if (
                        event.type === 'startProcess' &&
                        (event.component as any).properties?.processKey
                    ) {
                        const processKey = (event.component as any).properties
                            ?.processKey;
                        Router.navigate(`${url}/process/${processKey}`);
                    } else if (event.type === 'cancel') {
                        Router.navigate(entityUrl);
                    }

                    return Promise.resolve();
                }}
                onSubmit={async (submission) => {
                    const newItem = await store.update(
                        entityId,
                        submission.data,
                    );

                    if (newItem) {
                        await store.loadList();
                        Router.navigate(`${entityListUrl}/${newItem.id}`);
                    }
                }}
            />
        );
    },
);

const StyledSpinner = styled(Spinner)`
    margin: 0 auto;
    display: block;
`;
