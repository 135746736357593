import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import { Spinner } from 'src/components/Spinner/Spinner';
import { Router } from 'src/core/router/Router';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import styled from 'styled-components';

export type EntityCreateProps = {
    store: BasicStore<any>;
    entityListUrl: string;
};
export const EntityCreate = observer(
    ({ store, entityListUrl }: EntityCreateProps) => {
        const entityUrl = entityListUrl;

        useEffect(() => {
            store.loadForm(store.itemCreateFormKey);
            store.createItemLoader.reset();

            return () => {
                store.formLoader.reset();
            };
        }, []);
        const loadingStatus = useMemo(() => {
            return new CombinedLoadingStatus([store.formLoader]);
        }, []);

        if (loadingStatus.isLoading) {
            return <StyledSpinner />;
        }

        return (
            <FormioForm
                key="create"
                form={store.currentItemForm as any}
                submission={{ data: {} } as any}
                onSubmit={async (submission) => {
                    const newItem = await store.create?.(submission.data);

                    if (newItem) {
                        store.loadList();
                        Router.navigate(`${entityListUrl}/${newItem.id}`);
                    }
                }}
                onCustomEvent={async (event) => {
                    if (event.type === 'save') {
                        const newItem = await store.create?.(event.data);
                        if (newItem) {
                            store.loadList();
                            Router.navigate(`${entityListUrl}/${newItem.id}`);
                        }
                    } else if (event.type === 'cancel') {
                        Router.navigate(entityUrl);
                    }
                }}
            />
        );
    },
);

const StyledSpinner = styled(Spinner)`
    margin: 0 auto;
    display: block;
`;
